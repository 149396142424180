MarketReportsApp.Views.ReportRow = Backbone.View.extend({

    tagName: 'tr',

    initialize: function() {
        $('.my-reports-table').append(this.$el);
        this.render();
    },

    render: function() {
        this.$el.html(MarketReportsApp.Templates.ReportRow(this.model.toJSON()));
    }

});