let ProductRolesApp = new (Backbone.Router.extend({
	Models: {},
	Views: {},
	Collections: {},
	Variables: {},
	Templates: {},

	routes: {
		"product-roles/user-roles": "startProductRoles",
	},

	startProductRoles: function() {
        this.Variables.ProductRolesView = new ProductRolesApp.Views.ProductRoles();
	},
}));