MarketReportsApp.Views.PostProcessHistoryRow = Backbone.View.extend({

    tagName: 'tr',

    initialize: function() {
        $('.post-process-history-table').append(this.$el);
        this.render();
    },

    render: function() {
        this.$el.html(MarketReportsApp.Templates.PostProcessHistoryRow(this.model));
    }
});