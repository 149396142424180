FeedsApp.Views.FeedBreadcrumb = Backbone.View.extend({

    events: {
        "click .my-feeds": "myFeeds"
    },

    fetchTemplate: function() {
        var self = this;
        $.ajax({
            url: '/assets/js/feeds/history/templates/feed-breadcrumb.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
            },
            cache:true
        });
    },

    initialize: function() {
        var self = this;

        this.statusModel = new FeedsApp.Models.FeedStatus({id: this.model.get('id')});
        this.statusModel.fetch({
           success: function(model, response, options) {
               model.set('name', self.model.get('name'));
               self.fetchTemplate();
           }
        });
    },

    render: function() {
        this.$el.html(this.template(this.statusModel.toJSON()));
        $('.feed-breadcrumb-container').append(this.$el);
    },

    myFeeds: function() {
        window.location = '/feeds/'
    }
});