DataPackagesApp.Models.PackageModel = Backbone.Model.extend({
    urlRoot: '/api/data-packages/',
    defaults: {
        id: null,
        name: "",
        feeds: [],
        reports: [],
        organizations: []
    }
});

DataPackagesApp.Collections.PackagedModels = Backbone.Collection.extend({
    url: '/api/data-packages/',
    model: DataPackagesApp.Models.PackageModel
});