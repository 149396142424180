$(document).ready(function() {

	// This will disable the 'enter' key for any element (and children) inside of the the 'disable-enter-key' class
	$('.disable-enter-key').keypress(function(e) {
		if (e.keyCode == 13) {
            e.preventDefault();
        }
	});

	// This makes confirmation boxes work
	$('[data-toggle="confirmation"]').confirmation();

	// This creates a confirmation box if there is an active AjaxQ when a user tries to navigate off of a page.
	window.onbeforeunload = function() {
	    if ($.ajaxq.isRunning()) {
	    	// This doesn't actually show up in the dialog box...
	        return "You have unfinished requests. Are you sure you want to navigate away?";
	    } else {
	        null;
	    }
	};

    toastr.options = {
        "closeButton": true,
        "debug": false,
        "progressBar": true,
        "preventDuplicates": false,
        "positionClass": "toast-top-right",
        "onclick": null,
        "showDuration": "3000",
        "hideDuration": "1000",
        "timeOut": "7000",
        "extendedTimeOut": "3000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    Backbone.history.start({
        pushState: true
    });

});

var wizardHeight = 0;

function resizeJquerySteps() {
    if ($('.body.current').outerHeight() != wizardHeight) {
        $('.wizard .content').animate({
            height: $('.body.current').outerHeight()
        }, "fast");

        wizardHeight = $('.body.current').outerHeight();
    }
}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

// used to convert UTC hour to local time for scheduled daily feeds
function convertUTCHourToLocal(hourInUTC) {

    var timeInUTC = moment.utc().hour(hourInUTC);

    var localTime = timeInUTC.local();

    return localTime.format('h:00 A ') + moment.tz(moment.tz.guess()).format('z');
}

