FeedsApp.Views.NuFeedActions = ReadOnlyViewStandardModelEdition.extend({
    containerSelector: '.feed-actions-container',
    templateUrl: '/assets/js/feeds/history/templates/feed-actions.html',
    events: {
        "click .delete-feed-btn": "deleteFeed"
    },

    deleteFeed: function() {

        $('.deleting').toggleClass('hidden');
        $('.modal-footer').addClass('hidden');

        var self = this;
        $.ajax({
            url: self.model.get("delete_link"),
            type: 'DELETE',
            success: function () {
                toastr["success"]("Feed has been deleted.");
                window.location = '/feeds';
            },
            error: function() {
                toastr["error"]("Feed failed to delete.");
            }
        });
    }
});