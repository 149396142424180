var DataPackagesApp = new (Backbone.Router.extend({
    Models: {},
    Views: {},
    Collections: {},
    Variables: {},
    Templates: {},
    routes: {
        "data-packages/": "packageManager"
    },

    packageManager: function() {

        var collection = new DataPackagesApp.Collections.PackagedModels([]);
        new DataPackagesApp.Views.PackageManager({
            collection: collection
        });
    }
}));