FeedsApp.Views.FieldFilter = Backbone.View.extend({

    className: "row m-b filter-view",

    events: {
        "click .remove-field": "preRemove",
        "change .optional-condition": "toggleOptionalRequired",
        "change .secondary-internal-id": "toggleSecondaryAutocomplete",
        "change .secondary-condition-type": "checkSecondaryCondition",
        "change .primary-condition-type": "checkPrimaryCondition",
        "change .input-group [name]": "updateModel",
        "click .modal-view": "openModal"
    },

    primaryMultiple: true,
    primaryEl: function() {
        return '<select name="primary_value" class="form-control primary-condition primary-value required m-b" multiple></select>';
    },

    secondaryMultiple: true,
    secondaryEl: function() {
        return '<select name="secondary_value" class="form-control optional-condition secondary-value" multiple></select>';
    },

    initialize: function() {
        $('.filters-container').append(this.$el);
        this.render();
    },

    render: function() {
        this.$el.html(FeedsApp.Templates.FilterTemplate(this.model.toJSON()));
        this.listenTo(this.model, 'change', this.toggleExtendedField);
        this.activatePlugins();
    },

    activatePlugins: function() {
        var select2settings = this.fieldTypeCheck(this.model.get("internal_name"));

        // Initialize the primary value input box, populate nothing
        this.$el.find('.primary-value').select2({
            data: [],
            minimumInputLength: select2settings.minimumInputLength,
            tags: select2settings.tags,
            ajax: select2settings.ajax,
            placeholder: "Enter a value...",
            initSelection: function(element, callback) {
            },
            multiple: true,
            tokenSeparators: [",", "\n"]
        });

        // Initializes the secondary value input box as a select2, without real settings until we check the secondary condition.
        this.$el.find('.secondary-value').select2({
            data: [],
            minimumInputLength: 1,
            placeholder: "Enter a value...",
            tags: true,
            ajax: null,
            initSelection: function(element, callback) {
            },
            multiple: true,
            tokenSeparators: [",", "\n"]
        });

        // We need to do this every time in case information was set from the modal view
        this.checkPrimaryCondition();
        if (this.model.get('secondary_condition').get('id')) {
            this.checkSecondaryCondition();
        }
    },

    checkPrimaryCondition: function() {
        this.setPrimaryMultiple(true);
    },

    setPrimaryMultiple: function(value) {
        // This whole function needs to execute when editing an already-existing filter from the modal view
        // Otherwise, a list of multiple values is displayed as one value
        this.primaryMultiple = value;

        var id = this.$el.find('.primary-internal-id').val();

        // Combine filters from both JobFieldList and StructuredFieldList
        var type = FeedsApp.Variables.JobFieldList
            .concat(FeedsApp.Variables.StructuredFieldList)
            .filter(function (item) {
                return item.id === id;
            }).pop();

        if (type) {
            type = type.internal_name;
        } else {
            type = 'This function does not work!'; // If type is not found, it screws everything up for some reason
        }

        var settings = this.fieldTypeCheck(type);

        this.$el.find('.primary-value').select2('destroy');
        this.$el.find('.primary-value').replaceWith(this.primaryEl());

        // Initializes autocomplete in the new multiple input box.
        this.$el.find('.primary-value').select2({
            data: [],
            placeholder: "Enter a value...",
            ajax: settings.ajax,
            tags: settings.tags,
            minimumInputLength: settings.minimumInputLength,
            multiple: true,
            tokenSeparators: [",", "\n"]
        });

        // We need to do this every time in case information was set from the modal view
        this.prePopulateValues('primary_value', this.model.get('primary_available_field').get('internal_name'));
    },


    checkSecondaryCondition: function() {
        this.setSecondaryMultiple(true);
    },

    setSecondaryMultiple: function(value) {
        this.secondaryMultiple = value;

        var id = this.$el.find('.secondary-internal-id').val();

        // Combine filters from both JobFieldList and StructuredFieldList
        var type = FeedsApp.Variables.JobFieldList
            .concat(FeedsApp.Variables.StructuredFieldList)
            .filter(function(item) {
                return item.id === id;
            }).pop();

        if (type) {
            type = type.internal_name;
        } else {
            type = 'This function does not work!'; // If type is not found, it screws everything up for some reason
        }

        var select2settings = this.fieldTypeCheck(type);

        this.$el.find('.secondary-value').select2('destroy');
        this.$el.find('.secondary-value').replaceWith(this.secondaryEl());

        this.$el.find('.secondary-value').select2({
            data: [],
            minimumInputLength: select2settings.minimumInputLength,
            placeholder: "Enter a value...",
            tags: select2settings.minimumInputLength,
            ajax: select2settings.ajax,
            multiple: true,
            tokenSeparators: [",", "\n"]
        });

        // If the filter model has an optional secondary value, fetch multiple display names of the secondary filter values.
        if (this.model.get('secondary_condition').get('id')) {
            this.prePopulateValues('secondary_value', type);
            this.$el.find('select').removeClass('required');
        }
    },

    // Gets the namestrings of fields that were autocompleted when the feed was saved.
    prePopulateValues: function(primaryOrSecondary, fieldType) {
        var self = this;
        var fieldValues = this.model.get(primaryOrSecondary);
        if (fieldType === 'region' || fieldType === 'country' || fieldType === 'msa' || fieldType === 'onet_occupation_name') {
            if (fieldValues === "[]") { //hack for an empty array as a string
                return;
            }
            $.ajax({
                type: "GET",
                url: "/api/feeds/filters/fieldNameStrings",
                data: {fieldValues: fieldValues, fieldType: fieldType}
            }).then(function(response) {
                _.forEach(response.fieldNames, function (fieldInfo) {
                    var displayString = fieldInfo.fieldName + ' (' + fieldInfo.fieldValue + ')';
                    var option = $('<option selected="selected"></option>').val(fieldInfo.fieldValue).text(displayString);
                    primaryOrSecondary = primaryOrSecondary.replace('_', '-');
                    self.$el.find('.' + primaryOrSecondary).append(option);
                });
                self.$el.find('.' + primaryOrSecondary).trigger('change');
            });
        } else {
            if (typeof fieldValues === 'string') {
                fieldValues = JSON.parse(fieldValues);
            }

            fieldValues.forEach(function(item) {
                var option = $('<option selected="selected"></option>').val(item).text(item);
                primaryOrSecondary = primaryOrSecondary.replace('_', '-');
                self.$el.find('.' + primaryOrSecondary).append(option);
            });
            self.$el.find('.' + primaryOrSecondary).trigger('change');
        }
    },

    preRemove: function () {
        this.model.set({id: null});
        this.model.destroy(); // destroying the model removes it from the collection
        this.remove();
    },

    updateModel: function(e) {
        if (e.target.name === 'primary_value') {
            this.model.set('primary_value', this.$el.find('.primary-value').val());
        } else if (e.target.name === 'secondary_value') {
            this.model.set('secondary_value', this.$el.find('.secondary-value').val());
        } else if (e.target.name === 'primary_condition_type_id') {
            this.model.get('primary_condition').set("id", e.target.value);
            this.model.get('primary_condition').set("name", this.$el.find('.primary-condition-type option:selected').text());
        } else if (e.target.name === 'secondary_condition_type_id') {
            this.model.get('secondary_condition').set("id", e.target.value);
            this.model.get('secondary_condition').set("name", this.$el.find('.secondary-condition-type option:selected').text());
        } else if (e.target.name === 'secondary_internal_field_id') {
            this.setSecondaryAvailableField(this.$el);
        } else {
            this.model.set(e.target.name, e.target.value);
        }
    },

    setSecondaryAvailableField: function(el) {
        if (el.find('[name=secondary_internal_field_id]').val() !== "Select One") {

            // Combine JobFieldList and StructuredFieldList
            var combinedFieldList = FeedsApp.Variables.JobFieldList.concat(FeedsApp.Variables.StructuredFieldList);

            // Filter the combined list to find the selected field
            var availableField = combinedFieldList.filter(function(field) {
                return field.id === el.find('[name=secondary_internal_field_id]').val();
            })[0];

            if (availableField) {  // Ensure availableField is found
                this.model.get('secondary_available_field').set('internal_field_id', availableField.id)
                    .set('internal_name', availableField.internal_name);
                this.model.setPrimaryConditionByName('equals', FeedsApp.Variables.ConditionTypes);
            }
        }
    },


    toggleSecondaryAutocomplete: function() {
        // If a condition has not yet been picked, leave the select2 box alone.
        if (this.$el.find('.secondary-condition-type').val() === "") {
            return null;
        } else {
            this.checkSecondaryCondition();
        }
    },

    toggleExtendedField: function() {
        var extendedField = FeedsApp.Variables.JobFieldList.filter(function(field) {
            return field.internal_name === "extended";
        })[0];

        if (this.model.attributes.secondary_internal_field_id === extendedField.id && this.$el.find('.secondary-extended-field-name').hasClass('hidden')) {
            this.$el.find('.secondary-extended-field-name').removeClass('hidden');
        } else if (this.model.attributes.secondary_internal_field_id !== extendedField.id && !this.$el.find('.secondary-extended-field-name').hasClass('hidden')) {
            this.$el.find('.secondary-extended-field-name').addClass('hidden');
            this.model.set('secondary_extended_field_name', null);
        }
    },

    toggleOptionalRequired: function() {
        if ($('.secondary-internal-id').val() || $('.secondary-condition-type').val() || $('.secondary-value').val()) {
            this.$('.optional-condition').addClass('required');
        } else if (!$('.secondary-internal-id').val() || !$('.secondary-condition-type').val() || !$('.secondary-value').val()) {
            this.$('.optional-condition').removeClass('required');
        }
    },

    // Determines the select2 settings to return based on field type. Should probably have backend send a flag rather than hardcoding types here.
    fieldTypeCheck: function(type) {
        var ajax = null;
        var tags = true;
        var minimumInputLength = 1;

        if (type === 'region' || type === 'country' || type === 'msa' || type === 'onet_occupation_name') {
            ajax = {
                url: function(params) {
                    return "/api/feeds/filters/autocomplete/" + type + "/" + params.term + '?displayId';
                },
                delay: 250
            };
            tags = false;
            minimumInputLength = 2;
        }
        return {ajax: ajax, tags: tags, minimumInputLength: minimumInputLength};
    },

    openModal: function() {
        FeedsApp.Variables.CurrentFilterModalView = new FeedsApp.Views.FieldFilterModal({ parent: this });
    }
});