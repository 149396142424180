HomePageApp.Models.Chart = Backbone.Model.extend({

    defaults: {
        dates: null,
        created: null,
        deleted: null,
        active: null
    },

    getData: function() {
        var self = this;

        var types = [
            "active",
            "deleted",
            "created"
        ];

        _.each(types, function(type) {
            $.ajax({
                url: "/api/home/totals/" + type,
                success: function(data) {
                    self.injectData(data, type);
                }
            })
        });

        return true;
    },

    injectData: function(data, type) {
        var dates = [];
        var jobCounts = [];

        _.each(data, function(dataPoint) {
            dates.push(dataPoint.date);
            jobCounts.push(dataPoint.value);
        });

        switch (type) {
            case "active":
                this.set('active', jobCounts);
                break;
            case "deleted":
                this.set('deleted', jobCounts);
                break;
            case "created":
                this.set('created', jobCounts)
                break;
        };

        this.set('dates', dates);
    }

})