var CompanyListApp = {
    Models: {},
    Views: {},
    Collections: {},
    Variables: {},
    Templates: {},

    addCompany: function() {
        CompanyListApp.Variables.Edit = false;
        new CompanyListApp.Views.AddCompany({model: new CompanyListApp.Models.CompanyModel});
    },

    start: function(data) {
        CompanyListApp.Variables.coCollection = new CompanyListApp.Models.CompanyCollection(data, {parse: true});
        CompanyListApp.Variables.childrenViewTable = new CompanyListApp.Views.CompanyTable({collection: CompanyListApp.Variables.coCollection});
    },

    loadCompanies: function(companyData) {
        CompanyListApp.Variables.coCollection = new CompanyListApp.Models.CompanyCollection;
        _.each(companyData, function(row) {
            var company = new CompanyListApp.Models.CompanyModel({
                list_id: CompanyListApp.Variables.listId,
                company_id: row.linkup_company_id,
                stock_key: row.attributes.stock_key,
                stock_ticker: row.identifiers.stock_ticker,
                start_timestamp: row.attributes.start_timestamp,
                end_timestamp: row.attributes.end_timestamp,
                is_rollup_parent: CompanyListApp.Variables.isRollupParent,
                company_identifier_id: row.id,
                parent_id: CompanyListApp.Variables.parentId,
                tr_org_id: row.identifiers.tr_org_id,
                parent_tr_org_id: row.identifiers.parent_tr_org_id,
                company_name: row.details.company_name,
                ats: row.details.ats,
                num_jobs: row.details.num_jobs
            });
            CompanyListApp.Variables.coCollection.add(company);
        });
        new CompanyListApp.Views.CompanyTable({collection: CompanyListApp.Variables.coCollection});
    },

    loadParent: function(parent) {
        if (!_.isEmpty(parent)) {
            var parentModel = new CompanyListApp.Models.CompanyModel(parent, {parse: true});
            CompanyListApp.Variables.parentViewTable = new CompanyListApp.Views.ParentTable({model: parentModel});
        }
    },

    loadMissingChildren: function(children) {
        if (!_.isEmpty(children)) {
            CompanyListApp.Variables.missingChildrenFoundation = new CompanyListApp.Models.CompanyCollection(children, {parse: true});
            CompanyListApp.Variables.missingChildrenViewTable = new CompanyListApp.Views.MissingChildrensTable({collection: CompanyListApp.Variables.missingChildrenFoundation});
        }
    },

    Utilities: {
        flattenObject: function (input, result) {
            for (var key in input) {
                if (_.isObject(input[key])) {
                    result = CompanyListApp.Utilities.flattenObject(input[key], result);
                } else {
                    result[key] = input[key];
                }
            }
            return result;
        }
    }
};

$(document).ready(function() {
   $('button.add-new-company').on('click', function() {
       CompanyListApp.addCompany();
   });
});