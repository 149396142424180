FeedsApp.Views.Step2CustomTemplates = Backbone.View.extend({

	initialize: function() {
		var self = this;
		$.ajax({
			url: "/assets/js/feeds/feed-builder/templates/custom-templates.html",
			success: function(extTemplate) {
				self.template = _.template(extTemplate);
				self.render();
			},
            cache:true
		})
	},

	render: function() {
		this.$el.html(this.template());
		$('.custom-templates').append(this.$el);
	}
});