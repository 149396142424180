FeedsApp.Views.OrganizationAccessView = Backbone.View.extend({

    events: {
        "click .org-name": "fetchAssociationsForOrg",
        "click .feed-name": "fetchAssociationsForFeed"
    },

    initialize: function() {
        var self = this;

        $.ajax({
            url: '/assets/js/feeds/organization-access/templates/organization-access.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
            },
            cache:true
        });
    },

    render: function() {
        this.$el.html(this.template(FeedsApp.Variables.OrganizationAccessData));
        $('.feeds-organization-access-app-container').append(this.$el);

        this.selectedOrgView = new FeedsApp.Views.SelectedOrgView();
        this.selectedFeedView = new FeedsApp.Views.SelectedFeedView();

        // handle query parameters
        var url = document.location.href;
        if(url.indexOf('?') > -1) {
            this.jumpToSubView(url);
        }
    },

    jumpToSubView: function(baseUrl) {
        var param  = baseUrl.substring(baseUrl.indexOf('?') + 1, baseUrl.indexOf('='));
        var id  = baseUrl.substring(baseUrl.indexOf('=') + 1, baseUrl.length);

        if (param === 'org_id') {
            FeedsApp.Variables.SelectedOrg = id;
            this.clearDiv();
            this.selectedOrgView.refresh();
        }

        if (param === 'feed_id') {
            FeedsApp.Variables.SelectedFeed = id;
            this.clearDiv();
            this.selectedFeedView.refresh();
        }
    },

    appendParam: function(route) {
        var stateObj = { state: "queryParameter"};
        window.history.pushState(stateObj, "appended query param", route) ;
    },

    fetchAssociationsForOrg: function(e) {
        e.preventDefault();
        FeedsApp.Variables.SelectedOrg = $(e.currentTarget).attr("id");

        this.appendParam($(e.currentTarget).attr("href"));

        this.clearDiv();
        this.selectedOrgView.refresh();
    },

    fetchAssociationsForFeed: function(e) {
        e.preventDefault();
        FeedsApp.Variables.SelectedFeed = $(e.currentTarget).attr("id");

        this.appendParam($(e.currentTarget).attr("href"));

        this.clearDiv();
        this.selectedFeedView.refresh();
    },

    clearDiv: function() {
        $('.list-all-container').empty();
    }
});