MarketReportsApp.Views.ReportBreadcrumbView = Backbone.View.extend({

    events: {
        "click .view-reports": "viewReports"
    },

    fetchTemplate: function() {
        var self = this;
        $.ajax({
            url: '/assets/js/market-reports/report-details/templates/report-breadcrumb.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
            },
            cache:true
        });
    },

    initialize: function() {
        var self = this;

        var data = self.model.get('definition');
        var postProcessData = self.model.get('postProcessDefinition');
        this.statusModel = new MarketReportsApp.Models.ReportStatus({id: data.reportId});
        this.postProcessStatusModel = new MarketReportsApp.Models.PostProcessReportStatus({id: postProcessData.postProcessReportId});

        this.statusModel.fetch({
            success: function(model, response, options) {
                model.set('name', data.name);
                if (postProcessData.postProcessReportId) {
                    self.postProcessStatusModel.fetch({
                        success: function(model, response, options) {
                            self.fetchTemplate();
                        }
                    });
                } else {
                    self.fetchTemplate();
                }
            }
        });
    },

    render: function() {
        this.$el.html(this.template({"reportStatus": this.statusModel.toJSON(), "postProcessReportStatus": this.postProcessStatusModel.toJSON()}));
        $('.report-breadcrumb-container').append(this.$el);
    },

    viewReports: function() {
        window.location = '/market-reports/my-reports'
    }
});