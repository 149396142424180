MarketReportsApp.Views.ReportActionsView = Backbone.View.extend({

    events: {
        "click .delete-report-btn": "deleteReport"
    },

    initialize: function() {
        var self = this;
        $.ajax({
            url: '/assets/js/market-reports/report-details/templates/report-actions.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
            },
            cache:true
        });
    },

    render: function() {
        this.$el.html(this.template(this.model.toJSON()));
        $('.report-actions-container').append(this.$el);
    },

    deleteReport: function() {

        $('.deleting').toggleClass('hidden');
        $('.modal-footer').addClass('hidden');

        var self = this;

        $.ajax({
            type: 'POST',
            url:  '/api/reports/delete',
            data: {report_id: self.model.attributes.definition.reportId, is_archived: true},
            success: function(model, response) {
                toastr["success"]("Report has been deleted.");
                window.location = '/market-reports/my-reports';
            },
            error: function(model, response) {
                toastr["error"]("Report failed to delete.");
            },
        });
    }
});