ProductRolesApp.Views.ProductRoles = Backbone.View.extend({

	events: {
        "change .user-select": "productRoleFetch",
	},

	initialize: function() {
		var self = this;

		$.ajax({
			url: '/assets/js/product-roles/product-roles/templates/product-roles.html',
			success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
			},
			async: false,
			cache: false
		});
	},

	render: function() {
        this.$el.html(this.template);
        $('.jde-product-roles-app-container').append(this.$el);

        $('.user-select').select2({
            placeholder: "Select a User",
            ajax: {
                url: function(params) {
                    return "/api/users/product-roles/autocomplete/" + params.term;
                },
                delay: 250
            },
            minimumInputLength: 2
        });
	},

    refreshRoleTable: function(roleData) {
	    if (this.UserRolesView) {
            this.UserRolesView.remove();
        }

	    this.UserRolesModel = new ProductRolesApp.Models.UserRolesModel(roleData, {parse:true});
        this.UserRolesView = new ProductRolesApp.Views.UserRoles({model: this.UserRolesModel});
        this.UserRolesView.render();
    },

	productRoleFetch:function() {
	    var self = this;

        var selectedUserId = $('.user-select').val();

        $.ajax({
            url: '/api/users/product-roles/' + selectedUserId,
            success: function(response) {
                self.refreshRoleTable(response);
            },
            async: false,
            cache: false
        });
	}

});