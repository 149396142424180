FeedsApp.Views.ScheduleView = Backbone.View.extend({

	className: "row m-b",

	events: {
		"click .remove-schedule": "preRemove",
		"change .form-control": "updateModel",
		"change .frequency": "toggleFields",
	},

	initialize: function() {
		this.render();
	},

	render: function() {
		var self = this;
		this.$el.html(FeedsApp.Templates.ScheduleTemplate(this.model.toJSON()));
		$('.schedule-container').append(this.$el);

		$('.schedule-time').datetimepicker({
			stepping: 15
		}).on("dp.change", function(e) {
			self.updateModel(e);
		});
		this.toggleFields();
	},

    updateModel: function(e) {
        // not 'day to run'
        this.model.set({id:null});
        this.setModelAttribute(e.target.name, e.target.value);
    },

    setModelAttribute: function (attribute, value) {
	    if(attribute === 'frequency'){
	        this.setDefaults(value);
        }
        this.model.set(attribute, value);
    },

    setDefaults(value) {
	    var self = this;
        switch(value) {
            case 'interval':
                this.model.set({
                    'hour_to_run': self.$('[name="hour_to_run"]').val(),
                    'interval': self.$('[name="interval"]').val(),
                    'day_to_run': null
                });
                break;
            case 'daily':
                this.model.set({
                    'hour_to_run': self.$('[name="hour_to_run"]').val(),
                    'day_to_run': null,
                    'interval': null
                });
                break;
            default:
                this.model.set({
                    'day_to_run': self.$('[name="day_to_run"]').val(),
                    'hour_to_run': self.$('[name="hour_to_run"]').val(),
                    'interval': null
                });
        }
    },

	preRemove: function() {
        this.model.set({id: null});
        this.model.destroy(); // destroying the model removes it from the collection
        this.remove();
	},

	toggleFields: function() {
			switch(this.$('.frequency').val()){
				case 'interval':
                    this.$('.day-to-run').addClass('hidden');
                    this.$('.day-to-run-weekly').addClass('hidden');
                    this.$('.day-to-run-monthly').addClass('hidden');
                    this.$('.hour-to-run-group').removeClass('hidden');
                    this.$('.interval-group').removeClass('hidden');
                    break;
                case 'daily':
                    this.$('.day-to-run').addClass('hidden');
                    this.$('.day-to-run-weekly').addClass('hidden');
                    this.$('.day-to-run-monthly').addClass('hidden');
                    this.$('.hour-to-run-group').removeClass('hidden');
                    this.$('.interval-group').addClass('hidden');
                    break;
                case 'weekly':
                    this.$('.day-to-run').removeClass('hidden');
                    this.$('.day-to-run-weekly').removeClass('hidden');
                    this.$('.day-to-run-monthly').addClass('hidden');
                    this.$('.hour-to-run-group').removeClass('hidden');
                    this.$('.interval-group').addClass('hidden');
                    break;
                case 'monthly':
                    this.$('.day-to-run').removeClass('hidden');
                    this.$('.day-to-run-weekly').addClass('hidden');
                    this.$('.day-to-run-monthly').removeClass('hidden');
                    this.$('.hour-to-run-group').removeClass('hidden');
                    this.$('.interval-group').addClass('hidden');
                    break;
                default:
                    this.$('.day-to-run').addClass('hidden');
                    this.$('.day-to-run-weekly').addClass('hidden');
                    this.$('.day-to-run-monthly').addClass('hidden');
                    this.$('.hour-to-run-group').addClass('hidden');
                    this.$('.interval-group').addClass('hidden');
		}

	}
});