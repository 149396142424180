MarketReportsApp.Models.AggregationStatus = Backbone.Model.extend({

    defaults: {
        'aggregationScheduleId': null,
        'isActive': null
    },

    url: function () {
        return '/api/reports/aggregationschedules/aggregationstatus'
    }
});