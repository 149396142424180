HomePageApp.Views.Stats = Backbone.View.extend({

    className: "ibox float-e-margins",

    initialize: function() {
        var self = this;
        var type = this.model.get('id');

        $('.' + type + '-container').append(this.$el);

        $.ajax({
			url: '/assets/js/homepage/03_templates/stats.html',
			success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
                self.model.fetch();
			},
			cache: false
        });
        
        this.model.on('change', this.render, this);
    },

    render: function() {
        var model = this.model.toJSON();
        this.$el.html(this.template(model));

        if (model.value != null) {
            var className = this.model.get('id') + '-jobs';
            $('.' + className).removeClass('sk-loading');
        }
    }

});