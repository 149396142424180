FeedsApp.Models.FeedField = FeedsApp.Models.FeedsBase.extend({

    defaults: function() {
        return {
            id: null,
            available_field: new FeedsApp.Models.FeedAvailableField(),
            applied_order: null,
            client_field_name: null,
            cdata_flag: false,
            field_type_id: null,
            field_type: null,
            extended_field_name: null,
            static_value: null
        };
    },

    parse: function(data) {

        var returnData = {};

        returnData.available_field = new FeedsApp.Models.FeedAvailableField({
            'internal_field_id':data.feed_available_fields.id,
            'internal_name':data.feed_available_fields.internal_name
        });
        returnData.id = data.feed_fields.id;
        returnData.applied_order = data.feed_fields.applied_order;
        returnData.client_field_name = data.feed_fields.client_field_name;
        returnData.cdata_flag = data.feed_fields.cdata_flag;
        returnData.field_type_id = data.feed_fields.field_type_id;
        returnData.field_type = data.field_types.name;
        returnData.extended_field_name = data.feed_fields.extended_field_name;
        returnData.static_value = data.feed_fields.static_value;
        return returnData;
    }

});

FeedsApp.Collections.FeedFields = Backbone.Collection.extend({

    model: FeedsApp.Models.FeedField

});