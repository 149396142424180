FeedsApp.Views.FeedDefinition = Backbone.View.extend({

	initialize: function(models) {
		var self = this;
		this.model = models.model;
		this.associationsModel = models.associationsModel;
		$.ajax({
			url: '/assets/js/feeds/history/templates/feed-definition.html',
			success: function(extTemplate) {
				self.template = _.template(extTemplate);
				self.render();
			},
			cache:true
		});
	},

	render: function() {
		if (this.model.toJSON().template.name == 'Raw Archive Template') {
			$('.feed-definition').hide();
		} else {
			this.$el.html(this.template({"model": this.model.toJSON(), "associations": this.associationsModel.toJSON()}));
			$('.feed-definition-container').append(this.$el);

            // // Formatting the xml for the template preview. Todo: Rendered in the html with bootstrap
            // var prettifyXml = function(sourceXml)
            // {
            //     var xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
            //     var xsltDoc = new DOMParser().parseFromString([
            //         // describes how we want to modify the XML - indent everything
            //         '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
            //         '  <xsl:output omit-xml-declaration="yes" indent="yes"/>',
            //         '    <xsl:template match="node()|@*">',
            //         '      <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
            //         '    </xsl:template>',
            //         '</xsl:stylesheet>'
            //     ].join('\n'), 'application/xml');
            //
            //     var xsltProcessor = new XSLTProcessor();
            //     xsltProcessor.importStylesheet(xsltDoc);
            //     var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
            //     var resultXml = new XMLSerializer().serializeToString(resultDoc);
            //     return resultXml;
            // };
            // var data = this.model.toJSON();
            // var preview = data.template.preview.replace(/>\s*</g, '><');
            // $('.template-preview').text(prettifyXml(preview));
        }
	}
});