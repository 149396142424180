MarketReportsApp.Views.OrganizationAccessView = Backbone.View.extend({

    events: {
        "click .org-name-selected": "goToSelectedOrg",
        "click .agg-name-selected": "goToSelectedAgg",
        "click .expand-compress": "toggleExpandCompress"
    },

    initialize: function() {
        var self = this;

        $.ajax({
            url: '/assets/js/market-reports/organization-access/templates/organization-access.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
            },
            cache:true
        });

    },

    render: function() {

        this.$el.html(this.template(MarketReportsApp.Variables.Organizations));
        $('.mr-organization-access-app-container').append(this.$el);

        $('.all-orgs').DataTable({
            limit: 10
        });
        $('.all-aggs').DataTable({
            limit: 10
        });

        this.selectedOrgView = new MarketReportsApp.Views.SelectedOrgView();
        this.selectedAggView = new MarketReportsApp.Views.SelectedAggView();
    },

    goToSelectedOrg: function(e) {
        e.preventDefault();
        let id = $(e.currentTarget).attr("id");
        MarketReportsApp.Variables.SelectedOrg = id;
        $('.selected-organization').remove('.selected-organization-table');
        this.selectedOrgView.refresh();
    },

    goToSelectedAgg: function(e) {
        e.preventDefault();
        let id = $(e.currentTarget).attr("id");
        MarketReportsApp.Variables.SelectedAgg = id;
        $('.sk-aggregate').removeClass('hidden');
        $('.selected-aggregate-container').addClass('hidden');
        $('.selected-aggregate').remove('.selected-aggregate-table');
        this.selectedAggView.refresh();
    },

    toggleExpandCompress: function(e) {
        $(e.currentTarget).toggleClass('fa-compress').toggleClass('fa-expand');
    }

});