FeedsApp.Views.PostProcessWebhookView = Backbone.View.extend({

    className: "row m-b",

    events: {
        "click .remove-webhook": "preRemove",
        "change .form-control": "updateModel",
        "change .webhook-method": "toggleFields",
    },

    initialize: function() {
        this.render();
    },

    render: function() {
        var self = this;
        this.$el.html(FeedsApp.Templates.PostProcessWebhookTemplate(this.model.toJSON()));
        $('.webhook-container').append(this.$el);
        this.toggleFields();
    },

    updateModel: function(e) {
        this.model.set({id:null});
        this.setModelAttribute(e.target.name, e.target.value);
    },

    setModelAttribute: function (attribute, value) {
        this.model.set(attribute, value);
    },

    preRemove: function() {
        this.model.set({id: null});
        this.model.destroy(); // destroying the model removes it from the collection
        this.remove();
    },

    toggleFields: function() {
        switch(this.$('.webhook-method').val()){
            case 'post':
                this.$('.post-data').removeClass('hidden');
                break;
            case 'get':
                this.$('.post-data').addClass('hidden');
                break;
            default:
                this.$('.post-data').addClass('hidden');
        }
    }
});