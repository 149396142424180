FeedsApp.Views.FeedBuilderStep6 = Backbone.View.extend({

	className: "row",

	events: {
		"click .add-schedule": "addSchedule"
	},

    updateMasterModel: function() {
		// not implemented yet
	},

	initialize: function() {
		var self = this;

		$.ajax({
			url: '/assets/js/feeds/feed-builder/templates/step6.html',
			success: function (extTemplate) {
				self.template = _.template(extTemplate);
				self.render();

				$.ajax({
					url: '/assets/js/feeds/feed-builder/templates/schedule-template.html',
					success: function (extTemplate) {
						FeedsApp.Templates.ScheduleTemplate = _.template(extTemplate);
						self.model.get('feed_schedules').each(function (schedule) {
							new FeedsApp.Views.ScheduleView({model: schedule});
						})
					},
					dataType: 'html',
					cache: true
				});
			},
			dataType: 'html',
			cache: true
		});
	},

	render: function() {
		this.$el.html(this.template());
		$('#step6').append(this.$el);
	},

	addSchedule: function() {
		var scheduledFeed = new FeedsApp.Models.ScheduledFeeds();
        this.model.get('feed_schedules').add(scheduledFeed);
		new FeedsApp.Views.ScheduleView({ model: scheduledFeed });
	}
});