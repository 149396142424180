FeedsApp.Views.FeedBuilderStep7 = Backbone.View.extend({

    className: "row",

    events: {
        "click .add-webhook": "addWebhook"
    },

    updateMasterModel: function () {
        // not implemented yet
    },

    initialize: function () {
        var self = this;

        $.ajax({
            url: '/assets/js/feeds/feed-builder/templates/step7.html',
            success: function (extTemplate) {
                self.template = _.template(extTemplate);
                self.render();

                $.ajax({
                    url: '/assets/js/feeds/feed-builder/templates/post-process-webhook-template.html',
                    success: function (extTemplate) {
                        FeedsApp.Templates.PostProcessWebhookTemplate = _.template(extTemplate);
                        self.model.get('feed_webhooks').each(function (webhook) {
                            new FeedsApp.Views.PostProcessWebhookView({model: webhook});
                        })
                    },
                    dataType: 'html',
                    cache: true
                });
            },
            dataType: 'html',
            cache: true
        });
    },

    render: function () {
        this.$el.html(this.template());
        $('#step7').append(this.$el);
    },

    addWebhook: function () {
        var webhook = new FeedsApp.Models.PostProcessWebhook();
        this.model.get('feed_webhooks').add(webhook);
        new FeedsApp.Views.PostProcessWebhookView({model: webhook});
    }
});