FeedsApp.Models.FeedsBase = Backbone.Model.extend({

    setModelAttribute: function(key, value, options) {
        if ((key instanceof String || typeof key === 'string') && key.includes(".")) {
            var attrNames = key.split(".");
            var thingToModify = this;
            var finalAttrName = key;

            attrNames.forEach(function(attrName) {
                var attr = thingToModify.get(attrName);
                if (attr instanceof Backbone.Model) {
                    thingToModify = attr;
                }
                finalAttrName = attrName;
            });

            if (thingToModify instanceof FeedsApp.Models.FeedsBase) {
                thingToModify.setModelAttribute(finalAttrName, value, options);
            } else {
                thingToModify.set(finalAttrName, value, options);
            }

        } else {
            this.set(key,value,options);
        }
    },

    toJSON: function() {
        var json = _.clone(this.attributes);
        for(var attr in json) {
            if((json[attr] instanceof Backbone.Model) || (json[attr] instanceof Backbone.Collection)) {
                json[attr] = json[attr].toJSON();
            }
        }
        return json;
    }
});