FeedsApp.Views.FeedBuilderStep1 = Backbone.View.extend({

	events: {
		"change .feed-name": "uniqueNameCheck",
		"change .job-limit-checkbox": "toggleJobLimit",
		"change .form-group [name]": "updateModel",
		"click #dist-channel > button": "toggleDistributionChannels",
		"change #standard-checkbox": "standardizeDistributionChannels",
	},

	initialize: function() {
		var self = this;
		$.ajax({
			url: '/assets/js/feeds/feed-builder/templates/step1.html',
			success: function(extTemplate) {
				self.template = _.template(extTemplate);
				$('#step1').append(self.$el);
				self.render();
			},
			dataType: 'html',
				cache: false
		});
	},

	render: function() {
		this.$el.html(this.template(this.model.toJSON()));
		if (this.model.get("job_limit")) {
			$('.job-limit-checkbox').trigger('change');
		}

		// Triggering this change event is required to insert the default format value into the model
		$("[name='format']").trigger('change');

		this.activatePlugins();
		resizeJquerySteps();
	},

	updateModel: function (e) {
		if (e.target.type === "checkbox") {
			this.model.setModelAttribute(e.target.name, e.target.checked);
		} else if (e.target.name === 'name') {
			this.model.setModelAttribute('name', e.target.value.trim());
		} else if (e.target.name === 'job_limit' && e.target.value === "") {
			this.model.setModelAttribute('job_limit', null);
		} else if (e.target.name === 'org_associations') {

			var orgAssociations = new FeedsApp.Collections.OrgAssociations;
			var orgSelect = $(e.target);
            var orgSelections = orgSelect.select2('data');
            orgSelections.forEach(function(orgSelection) {
                var orgAssociation = new FeedsApp.Models.OrgAssociation();
                orgAssociation.set('organization_id', orgSelection.id);
                orgAssociation.set('organization_name', orgSelection.text);
                orgAssociations.add(orgAssociation);
            });

            this.model.set('org_associations', orgAssociations);
        } else {
			this.model.setModelAttribute(e.target.name, e.target.value);
		}
	},

	activatePlugins: function () {
		var orgSelect = $('.org-select');
		var self = this;
		$('.org-select').select2({
			placeholder: "Select an Organization",
			ajax: {
				url: function(params) {
					return "/api/users/organization/autocomplete/" + params.term;
				},
				delay: 250
			},
			minimumInputLength: 2
		});

		var orgSelections = orgSelect.select2('data');
		if (this.model.get('org_associations').length === 0) {
			orgSelections.forEach(function (orgSelection) {
				if (orgSelection.id !== null) {
					var orgAssociation = new FeedsApp.Models.OrgAssociation();
					orgAssociation.set('organization_id', orgSelection.id);
					orgAssociation.set('organization_name', orgSelection.text);
					self.model.get('org_associations').add(orgAssociation);
				}
			});
		}

		orgSelect.on('select2:opening', function(e) {

			if (self.model.get('id') != null) {
				swal({
					title: "Are you sure?",
					text: "By changing the organization, you are creating a new feed with the same settings as this feed for a new organization. Making changes from this point on will not affect the feed for the previous organization.",
					type: 'warning',
					showCancelButton: true,
				}).then(function(result) {
					if (result) {
						var feeds = self.model.set("id", null);

						var fields = self.model.get("job_fields");
						_.each(fields, function(field) {
							field.set('feed_id',  null);
							field.set('id', null);
						});

                        var filters = self.model.get('feed_filters');
                        _.each(filters, function(filter) {
                            filter.set('feed_id', null);
                            filter.set('id', null);
                        });

						self.model.set({
							'id': null,
							'org_associations':[],
							'feeds': feeds,
							'job_fields': fields,
							'feed_filters': filters
						});

						toastr["success"]("This will now be saved as a new feed");
                        orgSelect.val(null).trigger('change');

						// save new feed copy
						self.model.save(null, {
							success: function(model, response) {
								window.location = '/feeds/details/' + response.feed_id;
							},
							error: function(model, response) {
								toastr["error"](response.responseJSON.message, response.responseJSON.status);
							}
						})
					}
				})
			}
		});

	},

	uniqueNameCheck: function() {
		let self = this;
		fetch("/api/feeds/unique-name", {
			method: 'POST',
			body: JSON.stringify({feed_name: $('.feed-name').val(), org_id: self.model.get("org_associations").first().get("organization_id"), is_standard: self.model.get("is_standard")}),
		}).then(response => response.json())
			.then(function(response) {
				if (response.isUnique) {
					// TODO do we need to do shit here
				} else {
					$('.feed-name').val("");
					toastr["error"]("A feed with this name already exists for this organization. \n Please give the feed a unique name before attempting to save.");
				}
			})
	},

    toggleJobLimit: function() {
		$('.max-job-limit').toggleClass('hidden');

		if ($('.max-job-limit').hasClass('hidden')) {
            $('.job-limit').val("").trigger('change');
            $('.random-sort-checkbox').prop('checked', false).trigger('change');
		}
    },

	toggleDistributionChannels: function(e) {

		e.currentTarget.classList.toggle('btn-default');
		e.currentTarget.classList.toggle('btn-primary');

		let clickedElement = e.currentTarget.childNodes[1];
		clickedElement.classList.toggle('glyphicon-unchecked');
		clickedElement.classList.toggle('glyphicon-check');

		let channelDiv = document.getElementById('dist-channel');
		let modelChannels = this.model.get('distribution_channels');

		// Get all checked channels
		let checkedChannels = Array.from(channelDiv.querySelectorAll('.glyphicon-check')).map(function(checkedChannel) {
			return checkedChannel.parentElement.value;
		});

		if (clickedElement.classList.contains('glyphicon-unchecked')) { // If channel is unchecked, remove it
			let metricIndex = modelChannels.indexOf(clickedElement.parentElement.value);
			if (metricIndex !== -1) {
				modelChannels.splice(metricIndex, 1);
			}
		} else { // If channel is checked, add it
			let channelValue = clickedElement.parentElement.value;
			if (!modelChannels.includes(channelValue)) {
				modelChannels.push(channelValue);
			}
		}

		this.model.set({ distribution_channels: modelChannels });
	},

	standardizeDistributionChannels: function(e) {

		let val = e.currentTarget.checked;
		let distDiv = $('#dist-channel');
		distDiv.children(':button').each(function(index, element) {
			let el = $(element);
			let span = $(element.children[0]);
			if(val) {
				//order matters, has to be "enabled" for click event
				if(span.hasClass('glyphicon-unchecked')) {
					if (el.prop('value') !== 'brickftp') {
						el.trigger('click');
					}
				}
				else {
					if (el.prop('value') === 'brickftp') {
						el.trigger('click'); // Uncheck BrickFTP for standard reports
					}
				}
				el.prop('disabled', true);
			} else {
				//order matters, has to be "enabled" for click event
				el.prop('disabled', false);
				if(span.hasClass('glyphicon-check')) {
					el.trigger('click');
				}
			}
			element.classList.toggle('disabled');
		});
	}
});