MarketReportsApp.Views.NuMyReports = Backbone.View.extend({

    events: {
        "change .org-select": "updateView",
        "change .user-only": "toggleFilterByUser"
    },

    userOnly: false,

    initialize: function(options) {
        var self = this;

        this.collection.orgId = options.orgId;
        this.collection.orgName = options.orgName;
        this.userId = options.userId;
        $.ajax({
            url: '/assets/js/market-reports/nu-my-reports/templates/reports.html',
            success: function (extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
            },
            dataType: 'html',
            cache: false
        });

        $('.nu-my-reports-app-container').append(this.$el);
    },

    render: function() {
        this.$el.html(this.template({
            orgId: this.collection.orgId,
            orgName: this.collection.orgName,
            userOnly: this.userOnly
        }));

        var orgName = this.collection.orgName;
        $('.org-select').select2({
            placeholder: orgName,
            ajax: {
                url: function(params) {
                    return "/api/users/organization/autocomplete/" + params.term;
                },
                delay: 250
            },
            minimumInputLength: 2
        });
        this.renderRows();
    },

    renderRows: function() {
        var self = this;

        $.ajax({
            url: '/assets/js/market-reports/nu-my-reports/templates/report-row.html',
            success: function (extTemplate) {
                MarketReportsApp.Templates.ReportRow = _.template(extTemplate);
                self.collection.forEach(self.addOne, this);
                $('.table').DataTable({
                    pageLength:25,
                    columnDefs: [
                        { orderData: [8], targets: [7] },
                        { orderData: [11], targets: [10] },
                        {
                            targets: [8,11],
                            visible: false,
                            searchable: false
                        }
                    ]
                });
                $('.nu-my-reports-app-container').removeClass('sk-loading');
            },
            datatype: 'html',
            cache: false
        })
    },

    addOne: function(report) {
        new MarketReportsApp.Views.ReportRow({ model: report });
    },

    toggleFilterByUser: function(e) {
        var self = this;

        if (e.target.checked) {
            this.collection = new MarketReportsApp.Collections.NuMyReports(this.collection.where({user_id: this.userId}),
                {
                    orgId: this.collection.orgId,
                    orgName: this.collection.orgName
                }
            );
            this.userOnly = true;
            this.render();
        } else {
            this.userOnly = false;
            $('.nu-my-reports-app-container').addClass('sk-loading');
            this.collection.fetch({
                reset: true,
                success: function() {
                    self.render();
                }
            });
        }
    },

    updateView: function(e) {
        var self = this;

        this.collection.orgId = e.target.value;
        this.collection.orgName = $(e.target).find('option:selected').text();

        MarketReportsApp.Utils.updateSelectedOrg(this.collection.orgId, this.collection.orgName);

        $('.nu-my-reports-app-container').addClass('sk-loading');
        this.userOnly = false;
        this.collection.fetch({
            reset: true,
            success: function() {
                self.render();
            }
        });
    }
});