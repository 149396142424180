// Whatever instantiates this should decide whether the model needs to be fetched prior to view creation and pass it in without waiting for the model to sync.
var ReadOnlyViewStandardModelEdition = Backbone.View.extend({
    containerSelector: null,
    templateUrl: null,

    initialize: function() {
        if (!this.containerSelector || !this.templateUrl) {
            alert('containerSelector and templateUrl must be specified in the extending class!');
        }

        $(_.result(this, 'containerSelector')).append(this.$el);
        this.fetchTemplate(_.result(this, 'templateUrl'));
    },

    fetchTemplate: function(url) {
        var self = this;
        $.ajax({
            url: url,
            success: function(data) {
                self.template = _.template(data);
                self.render();
                self.model.on('change', self.render, self);
                self.trigger('template');
            }
        });
    },

    render: function() {
        this.$el.html(this.template(this.model.toJSON()));
        return this;
    }
});

// Whatever instantiates this should decide whether the collection needs to be fetched prior to view creation and pass it in without waiting for the model to sync.
var ReadOnlyViewSpecialCollectionEdition = Backbone.View.extend({
    containerSelector: null,
    templateUrl: null,

    // if you have views for each model in the collection, define this as a function(model) { return new ReadOnlyViewStandardModelEdition({model: model}); };
    // otherwise the collection template must handle it
    createNewModelView: false,

    initialize: function() {
        if (!this.containerSelector || !this.templateUrl) {
            alert('containerSelector and templateUrl must be specified in the extending class!');
        }

        $(_.result(this, 'containerSelector')).append(this.$el);
        this.fetchTemplate(_.result(this, 'templateUrl'));
    },

    fetchTemplate: function(url) {
        var self = this;
        $.ajax({
            url: url,
            success: function(data) {
                self.template = _.template(data);
                self.render();
                self.collection.once('sync', self.render, self);
                self.trigger('template');
            }
        });
    },

    render: function() {
        this.$el.html(this.template(this.collection.toJSON()));
        if (this.createNewModelView && this.collection.length > 0) {
            this.collection.forEach(this.createNewModelView, this);
            // re-rendering the collection is not included in the Special Collection Edition. Please purchase DynamicViewSpecialCollectionEdition
            this.createNewModelView = false;
        }
        return this;
    }
});
