MarketReportsApp.Models.AggegateEntry = Backbone.Model.extend({

    defaults: {
        'name': null,
        'id': null,
        'status': [],
        'reports': [],
        'scheduledReports': [],
        'org': []
    }
});

MarketReportsApp.Collections.AggregateRegistry = Backbone.Collection.extend({

    url: function () {
        return '/api/reports/org-to-aggregate/get-aggregation-schedules'
    },

    parse: function(response){
        return response.data;
    },

    model: MarketReportsApp.Models.AggegateEntry,

});