FeedsApp.Models.FeedsDefinition = FeedsApp.Models.FeedsBase.extend({
    urlRoot: '/api/feeds/details/definition',

    defaults: {
        "id": null,
        "name": null,
        "description": null,
        "feed_type": null,
        "generate_differential": false,
        "format": null,
        "zip_type": null,
        "run_link": null,
        "edit_link": null,
        "delete_link": null,
        "root_ftp_link": null,
        "feed_fields": {
            "job": [],
            "global": [],
            "mapped": []
        },
        "template": {
            "id": null,
            "name": null,
            "preview": ''
        },
        "feed_filters": [],
        "webhooks": [],
        "schedules": [],
        "distribution_channels": [],
    },

    parse: function(data) {

        if (data.template && data.template.preview) {
            data.template.preview = this.getBodyPositiveXML(data.template.preview);
        }
        return data;
    },

    getBodyPositiveXML: function(xmlString) {
        // using XSLTProcessor.importStylesheet to produce a pretty print of xml causes Firefox to freak out and break things,
        // so we are instead going to produce a body positive xml string because xml strings of all shapes and sizes are beautiful
        if (typeof xmlString === 'string') {
            return xmlString.replace(/>\s*</g, '>\n<');
        }
        return '';
    }
});