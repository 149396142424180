FeedsApp.Models.FeedsManage = Backbone.Model.extend({

    parse: function(data) {
        data.last_generated_timestamp = data.last_generated_date;
        data.last_generated_date = this.convertToDateString(data.last_generated_date);
        if (data.number_of_jobs && window.Intl && typeof window.Intl === 'object') {
            data.number_of_jobs = new Intl.NumberFormat('en-US').format(data.number_of_jobs);
        }
        if (typeof data.delivery_status !== 'undefined') {
            data.on_time_or_overdue = data.delivery_status;
        }
        else {
            data.on_time_or_overdue = 'Unknown';
        }
        return data;
    },
    convertToDateString: function(timeStamp) {
        if (typeof timeStamp === 'undefined' || !(timeStamp > 0)) {
            return timeStamp;
        }
        return moment.unix(timeStamp).format('M/D/YYYY h:mm A ') + moment.tz(moment.tz.guess()).format('z');
    }
});

FeedsApp.Collections.FeedsManage = Backbone.Collection.extend({
    initialize: function(models, options) {
        this.orgId = options.orgId;
        this.orgName = options.orgName;
    },
    url: function () {
        return '/api/feeds/' + this.orgId;
    },
    model: FeedsApp.Models.FeedsManage
});