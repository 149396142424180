MarketReportsApp.Views.SelectedAggView = Backbone.View.extend({
    tagName: 'div',

    events: {
        "click .saveButton": "updateAssociations",
        'change input[type=radio]': 'changedRadio'
    },

    initialize: function() {

        this.model = new MarketReportsApp.Models.SelectedAgg;
        var self = this;
        $.ajax({
            url: '/assets/js/market-reports/organization-access/templates/selected-aggregate.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
            },
            cache:true
        });

        $('.selected-aggregate').append(this.$el);
    },

    render: function() {

        this.$el.html(this.template(this.model.toJSON()));
        $('.sk-aggregate').addClass('hidden');
        $('.selected-aggregate-table').DataTable({
            limit: 10
        });
    },

    refresh: function() {
        this.model.clear();
        self = this;
        this.model.fetch({
            success: function() {
                self.render();
            }
        });
    },

    reset: function() {
        this.$el.empty();
    },

    changedRadio: function(e) {
        e.preventDefault();
        $(e.currentTarget).addClass("changed");
    },

    updateAssociations: function(e) {

        $('input:radio').each(function() {
            if ($(this).prop('checked') && $(this).hasClass('changed')) {
                var orgId = $(this).attr('name');

                $('.saveButton').replaceWith('<div class="sk-spinner sk-spinner-wandering-cubes sk-updating-aggregates"><div class="sk-cube1"></div><div class="sk-cube2"></div></div>');
                switch ($(this).attr('value')) {
                    case "enable":
                        var modelToSave = new MarketReportsApp.Models.UpdatedAssociation({
                            url: '/api/reports/org-to-aggregate/save',
                            org_id: orgId,
                            aggregation_schedule_id: MarketReportsApp.Variables.SelectedAgg
                        });
                        modelToSave.save(null, {
                            success: function(model, response) {
                                $('.sk-updating-aggregates').remove();
                                toastr.success(
                                    "Success! Refresh the page to see the changes.",
                                    "Organization Enabled"
                                );

                            },
                            error: function(model, response) {
                                $('.sk-updating-aggregates').remove();
                                toastr["error"]("Warning: updated association " + response.responseJSON.data + " failed to save.");
                            }
                        });
                        break;
                    case "disable":
                        var modelToDelete = new MarketReportsApp.Models.UpdatedAssociation({
                            url: '/api/reports/org-to-aggregate/delete',
                            org_id: orgId,
                            aggregation_schedule_id: MarketReportsApp.Variables.SelectedAgg
                        });
                        modelToDelete.save(null, {
                            success: function(model, response) {
                                $('.sk-updating-aggregates').remove();
                                toastr.success(
                                    "Success! Refresh the page to see the changes.",
                                    "Organization disabled"
                                );

                            },
                            error: function(model, response) {
                                $('.sk-updating-aggregates').remove();
                                toastr["error"]("Warning: updated association " + response.responseJSON.data + " failed to save.");
                            }
                        });
                        break;
                }

            }
        });
    }

});