MarketReportsApp.Models.UpdatedAssociation = Backbone.Model.extend({

    initialize: function() {
        this.url = this.get("url");
    },

});

MarketReportsApp.Collections.UpdatedAssociations = Backbone.Collection.extend({
    model: MarketReportsApp.Models.UpdatedAssociation
});