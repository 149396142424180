MarketReportsApp.Views.DimensionValues = Backbone.View.extend({

    tagName: "section",

    events: {
        "click .edit-button": "editList",
        "click .delete-button": "deleteList",
        "click .delete-list": "deleteListModal",
        "click .cancel-delete-button": "closeDeleteModal",
        "change .org-select": "updateView",
        "click .view-values": "loadDimensionValues"
    },


    initialize: function(options) {
        var self = this;
        this.collection.orgId = options.orgId;
        this.collection.orgName = options.orgName;

        $.ajax({
            url: '/assets/js/market-reports/DimensionValues/templates/DimensionValues.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
            }
        });
    },

    render: function() {
        this.$el.html(this.template({
            orgId: this.collection.orgId,
            orgName: this.collection.orgName
        }));
        $('.dimension-values-app-container').append(this.$el);

        $('.org-select').select2({
            placeholder: this.orgName,
            ajax: {
                url: function(params) {
                    return "/api/users/organization/autocomplete/" + params.term;
                },
                delay: 250
            },
            minimumInputLength: 2
        });
    },

    loadDimensionValues: function(e) {
        var orgId = this.collection.orgId;
        var listId = $(e.currentTarget).attr('data-list-id');
        $('.my-lists-loading').removeClass('hidden');

        $.ajax({
            type: 'GET',
            url:  '/api/reports/dimensions/lists/' + listId + '/org/' + orgId,
            success: function(model, response) {
                var valuesDiv = $('#values_' + listId);
                for (const id in model.values) {
                    valuesDiv.append("<span>" + model.values[id] + "</span><br>");
                }
                $('.my-lists-loading').addClass('hidden');
            },
            error: function(response) {
                toastr.options.timeOut = 0;
                toastr.options.tapToDismiss = false;
                toastr["error"](response.responseJSON.message).css("width", "350px");
            },
        });
    },

    deleteList: function(e) {
        var listId = $(e.target).closest('div').attr('id');

        $.ajax({
            type: 'DELETE',
            url:  '/api/reports/dimensions/lists/' + listId,
            success: function(model, response) {
                toastr["success"]("List has been deleted. Refreshing page...");
                window.location = '/market-reports/dimension-values';
            },
            error: function(response) {
                toastr.options.timeOut = 0;
                toastr.options.tapToDismiss = false;
                toastr["error"](response.responseJSON.message).css("width", "350px");
            },
        });
    },

    deleteListModal: function(e) {
        var listId = $(e.target).closest('div').attr('id');
        $('#confirm-delete .modal-footer').attr('id', listId);
    },

    closeDeleteModal: function(e) {
        $('#confirm-delete .modal-footer').removeAttr("id");
    },

    editList: function(e) {
        window.location = '/market-reports/dimension-values/edit/' + $(e.target).closest('div').attr('id');
    },

    updateView: function(e) {
        $('.lists-collection-container').addClass('hidden');
        $('.my-lists-loading').removeClass('hidden');

        var self = this;

        this.collection.orgId = e.target.value;
        this.collection.orgName = $(e.target).find('option:selected').text();

        MarketReportsApp.Utils.updateSelectedOrg(this.collection.orgId, this.collection.orgName);

        $('.feeds-manage-app-container').addClass('sk-loading');
        this.collection.fetch({
            reset: true,
            success: function() {
                $('.my-lists-loading').addClass('hidden');
                self.render();
                $('.lists-collection-container').removeClass('hidden');
            }
        });
    }
});