WhatsRunningApp.Models.PostProcess = Backbone.Model.extend({
    
    url: function() {
        return "/api/whats-running/post-process"
    },
    
    defaults: {
        postprocess: ['initial state']
    },

    parse: function (data) {
        var model = {};
        model.postprocess = data;
        return model;
    }
});