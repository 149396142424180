MarketReportsApp.Views.DimensionValuesAddEdit = Backbone.View.extend({

    tagName: "section",

    events: {
        "change .dimension-type-select": "changeDimensionType",
        "change .is-standard-checkbox": "switchIsStandard",
        "click .save-list": "parseAndSave",
        "click .modal-view": "openModal",
        "change .form-group [name]": "updateModel"
    },

    initialize: function(options) {
        let self = this;
        self.dimensionTypeSelections = options.dimensionTypeSelections;

        $('.dimension-values-app-container').append(this.$el);

        $.ajax({
            url: '/assets/js/market-reports/DimensionValues/templates/DimensionValuesAddEdit.html',
            success: function (extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
            },
            cache: false
        });

        $.ajax({
            url: '/assets/js/market-reports/DimensionValues/templates/valuesModal.html',
            success: function(extTemplate) {
                MarketReportsApp.Templates.ListBuilderModalTemplate = _.template(extTemplate);
            },
            dataType: 'html',
            cache: false
        });
    },

    render: function() {
        let  self = this;

        $.ajax({
            url: '/api/reports/dimensions/all',
            success: function (data) {
                if(MarketReportsApp.Variables.InReportBuilder) {
                    MarketReportsApp.Variables.DimensionTypes = self.dimensionTypeSelections;
                } else {
                    MarketReportsApp.Utils.sortAlphabetic(data);
                    MarketReportsApp.Variables.DimensionTypes = data;
                }
                self.$el.html(self.template(self.model.toJSON()));
                if (self.model.get('id') !== null) {
                    self.populateOrgList();
                    self.populateDimensionList();
                }
                self.initPlugins();

            },
            cache: false
        });
    },

    initPlugins: function() {
        var self = this;
        $('.list-org-select').select2({
            placeholder: "Select an Organization",
            ajax: {
                url: function(params) {
                    return "/api/users/organization/autocomplete/" + params.term;
                },
                delay: 250
            },
            minimumInputLength: 2
        });
        if (self.model.get('id') === null) {
            this.model.set({ org_id: parseInt($('.list-org-select').val()) });
        }
    },

    changeDimensionType: function() {
        this.model.set("values", null);
        $('.dimension-value-list').val(null).trigger('change');

        this.showListValueInput();
    },

    showListValueInput: function() {
        var dimensionTypeText = $('.dimension-type-select option:selected').text();
        var dimensionTypeId = $('.dimension-type-select option:selected').val();

        $('.dimension-list').addClass('hidden');

        var displayId = '';
        if (dimensionTypeText == 'Company' || dimensionTypeText == 'MSA' || dimensionTypeText == 'NAICS 3-Digit') {
            displayId = '?displayId';
        }

        var minimumInputLength = 2;
        if (dimensionTypeText == 'Stock Ticker v2') {
            minimumInputLength = 1;
        }

        $('.dimension-value-list').select2({
            placeholder: "Select a " + dimensionTypeText,
            ajax: {
                url: function (params) {
                    return "/api/reports/dimensions/autocomplete/id/" + dimensionTypeId + "/" + params.term + displayId;
                },
                delay: 250
            },
            minimumInputLength:  minimumInputLength
        });
        $('.dimension-list').removeClass('hidden');
    },

    setModelAttribute: function (attribute, value) {
        this.model.set(attribute, value);
    },

    updateModel: function(e) {
        if (e.target.type === "checkbox") {
            this.setModelAttribute(e.target.name, e.target.checked);
        } else {
            this.model.set(e.target.name, e.target.value);
        }
    },

    populateDimensionList: function() {
        var values = [];
        var dimensionTypeId = this.model.get('dimension_type_id');
        this.model.get('values').forEach(function(listItem) {
            values.push(listItem.value);
        });
        $.ajax({
            type: 'POST',
            url:  "/api/reports/dimensions/dimensionNameString/" + dimensionTypeId,
            data: { dimensionValues: values },
            success: function(response) {
                _.forEach(response.dimensionNames, function (dimension) {
                    var option = $('<option selected="selected"></option>').val(dimension.dimensionValue).text(dimension.dimensionName);
                    $('.dimension-value-list').append(option).trigger('change');
                });
            }
        });
        this.showListValueInput();
    },

    checkAutocomplete: function(values) {
        var dimensionTypeId = this.model.get('dimension_type_id');
        $.ajax({
            type: 'POST',
            url:  "/api/reports/dimensions/dimensionNameString/" + dimensionTypeId,
            data: { dimensionValues: values },
            success: function(response) {
                _.forEach(response.dimensionNames, function (dimension) {
                    var option = $('<option selected="selected"></option>').val(dimension.dimensionValue).text(dimension.dimensionName);
                    $('.dimension-value-list').append(option).trigger('change');
                });
                $('.find-by-values-loading').addClass('hidden');
            }
        });
    },

    populateOrgList: function() {
        var self = this;


        $.ajax({
            type: 'POST',
            url: '/api/users/organizations/ids',
            data: { org_ids:  self.model.get('org_id') },
            success: function(response) {
                _.forEach(response.organizations, function (organization) {
                    var option = $('<option selected="selected"></option>').val(organization.id).text(organization.name);
                    $('.list-org-select:visible').append(option).trigger('change');
                });
            }
        });

    },


    toggleOrgSelect: function() {
        $('.org-select-wrapper').toggleClass('hidden');
    },

    switchIsStandard: function (e) {
        this.updateModel(e);
        this.toggleOrgSelect();
    },

    parseAndSave: function() {
        if (!MarketReportsApp.Variables.InReportBuilder) {
            this.model.parseModel();
        }
    },

    openModal: function() {
        MarketReportsApp.Variables.ListBuilderValuesModal = new MarketReportsApp.Views.ListBuilderValuesModal({ parent: this, values: $('.dimension-value-list').select2('data')});
    }
});