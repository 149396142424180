MarketReportsApp.Views.ListBuilderValuesModal = Backbone.View.extend({

    className: "modal-filter-view",

    events: {
        "change .form-group [name]": "updateModel",
        "click .autocomplete": "checkAutocomplete",
        "click .confirm": "submit",
        "click .close": "close"
    },

    initialize: function(options) {
        this.parent = options.parent;
        this.model = this.parent.model.clone();
        this.values = options.values;

        console.log(this.values);

        $('.dimension-values-app-container').append(this.$el);
        this.render();
    },

    render: function() {
        var namesOfValues = [];
        _.each(this.values, function(valueObject) {
            namesOfValues.push(valueObject.id);
        });
        this.$el.html(MarketReportsApp.Templates.ListBuilderModalTemplate({namesOfValues: namesOfValues}));
    },

    updateModel: function(e) {
        if (e.target.name === 'values') {
            $('.find-by-values-loading').removeClass('hidden');
            this.parent.checkAutocomplete(e.target.value.trim().split("\n"));
        } else {
            this.model.set(e.target.name, e.target.value);
        }
    },

    submit: function () {
        this.parent.model = this.model.clone();
        this.remove();
    },

    close: function() {
        this.remove();
    }
});