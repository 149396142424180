CompanyListApp.Views.CompanyRow = Backbone.View.extend({

    tagName: "tr",
    className: "company-row",

    events: {
        'click button.edit-details': 'editDetails',
        'click button.delete-company': 'deleteCompany'
    },

    initialize: function() {
        var self = this;
        self.listenTo(self.model, 'change', self.render);
        self.listenTo(self.model, 'destroy', self.remove);
        $('table.company-table tbody').append(self.$el);
        self.render();
    },

    render: function() {
        this.$el.html(CompanyListApp.Templates.CompanyRow(this.model.attributes));
        return this;
    },

    editDetails: function() {
        CompanyListApp.Variables.Edit = true;
        new CompanyListApp.Views.AddCompany({model: this.model});
        $('html, body').scrollTop($('div.add-new-company').offset().top);
    },

    deleteCompany: function() {
        var self = this;

        swal({
            type: "warning",
            title: "Are you sure you want to delete?",
            text: "This cannot be undone",
            showCancelButton: true
        }).then(function() {
            self.model.destroy({
                success: function() {
                    toastr["success"]("Successfully deleted.");
                    self.remove();
                },
                error: function() {
                    toastr["warning"]("Something went wrong.");
                }
            });
        });
    }
});