CompanyListApp.Views.AddCompany = Backbone.View.extend({

    events: {
        'click button.close-form': 'closeForm',
        'click button.add-company': 'submitForm'
    },

    initialize: function() {
        var self = this;
        var templateUri = '/assets/js/company-list/templates/add-company-form.html';
        if (this.model.attributes.linkup_company_id != null) {
            templateUri = '/assets/js/company-list/templates/add-child-company-form.html';
        }
        $.ajax({
            url: templateUri,
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
                $('div.add-new-company').append(self.$el);
                self.render();
                $('button.add-new-company').hide();
            },
            dataType: 'html',
            cache: false
        });
    },

    render: function() {
        this.$el.html(this.template(this.model.attributes));
        $('.company-select').select2({
            placeholder: "Select a company",
            ajax: {
                url: function(params) {
                    return "/api/reports/dimensions/autocomplete/Company/" + params.term + '?displayId';
                },
                delay: 250
            },
            minimumInputLength: 2
        });
        return this;
    },

    closeForm: function() {
        $('button.add-new-company').show();
        this.remove();
    },

    submitForm: function() {
        var self = this;

        var attributes = {
            linkup_company_id: $('[name="companyName"]').val(),
            list_id: CompanyListApp.Variables.listId,
            start_timestamp: $('[name="startDate"]').val(),
            end_timestamp: $('[name="endDate"]').val(),
            stock_key: $('[name="stockKey"]').val(),
            is_rollup_parent: CompanyListApp.Variables.isRollupParent,
            parent_id: CompanyListApp.Variables.parentId
        };

        this.model.save(attributes, {
            wait: true,
            success: function (model, response, options) {
                self.closeForm();
                if (!CompanyListApp.Variables.childrenViewTable.collection.get(model.attributes.id)) {
                    CompanyListApp.Variables.childrenViewTable.collection.unshift(model);
                } else {
                    $('html, body').scrollTop($('#' + model.attributes.id).offset().top);
                }
            },
            error: function (model, xhr, options) {
                var msg = "Failed to add company.";
                if (xhr.responseJSON && xhr.responseJSON.message) {
                    msg = xhr.responseJSON.message;
                }
                alert(msg);
            }
        });
    }
});