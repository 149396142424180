FeedsApp.Models.FeedFilter = FeedsApp.Models.FeedsBase.extend({

    defaults: function() {
        return {
            id: null,
            mode: 'include',

            primary_available_field: new FeedsApp.Models.FeedAvailableField(),
            primary_extended_field_name: null,
            primary_condition: new FeedsApp.Models.FeedsBase({
                id: null,
                name: null
            }),
            primary_value: [],
            secondary_available_field: new FeedsApp.Models.FeedAvailableField(),

            secondary_extended_field_name: null,
            secondary_condition: new FeedsApp.Models.FeedsBase({
                id: null,
                name: null
            }),
            secondary_value: []
        }
    },

    setPrimaryConditionByName: function(conditionName, availableConditions) {
        var condition = _.find(availableConditions, function(item) {
            return item.name === conditionName;
        });
        this.get("primary_condition").set("id", condition.id);
        this.get("primary_condition").set("name", conditionName);
        return this;
    },

    setPrimaryInternalFieldByName: function(fieldName, availableFieldList) {
        var field = _.find(availableFieldList, function(item) {
            return item.internal_name === fieldName;
        });
        this.get("primary_available_field").setModelAttribute('internal_field_id', field.id);
        this.get("primary_available_field").setModelAttribute('internal_name', fieldName);
        return this;
    },
    parse: function(data) {
        var returnData = {};

        returnData.id = data.feed_filters.id;
        returnData.mode = data.feed_filters.mode;
        returnData.primary_extended_field_name = data.feed_filters.primary_extended_field_name;
        returnData.primary_value = data.feed_filters.primary_value;
        returnData.primary_available_field = new FeedsApp.Models.FeedAvailableField({
            'internal_field_id':data.feed_available_fields.primary.id,
            'internal_name':data.feed_available_fields.primary.name
        });

        returnData.primary_condition = new FeedsApp.Models.FeedsBase({
            'id':data.condition_types.primary.id,
            'name':data.condition_types.primary.name
        });

        returnData.secondary_extended_field_name = data.feed_filters.secondary_extended_field_name;
        returnData.secondary_value = data.feed_filters.secondary_value;
        returnData.secondary_available_field = new FeedsApp.Models.FeedAvailableField({
            'internal_field_id':data.feed_available_fields.secondary.id,
            'internal_name':data.feed_available_fields.secondary.name
        });

        returnData.secondary_condition = new FeedsApp.Models.FeedsBase({
            'id':data.condition_types.secondary.id,
            'name':data.condition_types.secondary.name
        });

        return returnData;
    },

    setFilterValue: function(primaryOrSecondary, value) {

        if (value.toString().indexOf(',') > -1) {
            //var array = value.toString().split(',');
            var array = Object.values(value);
            console.log("multi values:" + typeof(array) + ' ' + primaryOrSecondary);
            this.set(primaryOrSecondary, array);
        } else {
            console.log("not multi values:" + value);
            this.set(primaryOrSecondary, value);
        }

        console.log(this);
    }

});

FeedsApp.Collections.FeedFilters = Backbone.Collection.extend({

    model: FeedsApp.Models.FeedFilter

});