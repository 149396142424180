HomePageApp.Models.RecentReports = Backbone.Model.extend({
    
    url: function() {
        var orgId = user.get('orgId');
        return "/api/reports/nu-my-reports/" + orgId + "?sort=report_last_run"
    },
    
    defaults: {
        reports: []
    },

    parse: function (data) {
        var model = {};
        data = data.slice(0,10);
        model.reports = data;
        return model;
    }

});