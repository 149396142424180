MarketReportsApp.Models.NuMyReport = Backbone.Model.extend({

    parse: function(data) {
        data.report_last_run_timestamp = data.report_last_run;
        data.report_last_run = this.convertToDateString(data.report_last_run);
        data.post_process_last_run_timestamp = data.post_process_last_run;
        data.post_process_last_run = this.convertToDateString(data.post_process_last_run);

        return data;
    },
    convertToDateString: function(timeStamp) {
        if (typeof timeStamp === 'undefined' || !(timeStamp > 0)) {
            return timeStamp;
        }
        return moment.unix(timeStamp).format('MM/DD/YYYY h:mm A ') + moment.tz(moment.tz.guess()).format('z');
    }
});

MarketReportsApp.Collections.NuMyReports = Backbone.Collection.extend({
    initialize: function(models, options) {
        this.orgId = options.orgId;
        this.orgName = options.orgName;
    },
    url: function () {
        return '/api/reports/nu-my-reports/' + this.orgId;
    },
    model: MarketReportsApp.Models.NuMyReport
});