DataPackagesApp.Views.EditPackage = DynamicViewStandardModelEdition.extend({
    containerSelector: '.edit-package-container',
    templateUrl: '/assets/js/data-packages/package-builder/templates/edit-package.html',
    activatedPlugins: false,
    events: {
        "click .cancel": "close",
        "click .update-package": "submit"
    },

    initialize: function() {
        DynamicViewStandardModelEdition.prototype.initialize.apply(this, arguments);

    },

    render: function() {
        DynamicViewStandardModelEdition.prototype.render.apply(this, arguments);

        if (!this.activatedPlugins) {
            this.activatePlugins();
            this.activatedPlugins = true;
        }
    },

    activatePlugins: function() {
        this.$el.find('.multiple-feed-select').select2({
            placeholder: "Select Feeds",
            ajax: {
                url: function(params) {
                    return "/api/data-packages/feed/autocomplete/" + params.term;
                },
                delay: 250
            },
            minimumInputLength: 2
        });
        this.$el.find('.multiple-report-select').select2({
            placeholder: "Select Reports",
            ajax: {
                url: function(params) {
                    return "/api/data-packages/report/autocomplete/" + params.term;
                },
                delay: 250
            },
            minimumInputLength: 2
        });
        this.$el.find('.multiple-org-select').select2({
            placeholder: "Select Organizations",
            ajax: {
                url: function(params) {
                    return "/api/users/organization/autocomplete/" + params.term;
                },
                delay: 250
            },
            minimumInputLength: 2
        });

        this.populateSelect2();
    },

    populateSelect2: function() {
        var feedEl = this.$el.find('.multiple-feed-select');

        _.each(this.model.get('feeds'), function(feed) {
            var option = $('<option selected="selected"></option>').val(feed.feed_id).text(feed.feed_name);
            feedEl.append(option);
        });
        feedEl.trigger('change');

        var reportEl = this.$el.find('.multiple-report-select');
        _.each(this.model.get('reports'), function(report) {
            var option = $('<option selected="selected"></option>').val(report.report_id).text(report.report_name);
            reportEl.append(option);
        });
        reportEl.trigger('change');

        var orgEl = this.$el.find('.multiple-org-select');
        _.each(this.model.get('organizations'), function(org) {
            var option = $('<option selected="selected"></option>').val(org.organization_id).text(org.organization_name);
            orgEl.append(option);
        });
        orgEl.trigger('change');
    },

    updateModel: function() {
        var packageId = this.model.get('id');
        var el = this.$el;

        var feeds = [].map.call(el.find('[name=feeds] option:selected'),function(node) {
            return {
                feed_id: node.value,
                feed_name: node.text,
                package_id: packageId
            };
        });
        var reports = [].map.call(el.find('[name=reports] option:selected'),function(node) {
            return {
                report_id: node.value,
                report_name: node.text,
                package_id: packageId
            };
        });
        var orgs = [].map.call(el.find('[name=organizations] option:selected'),function(node) {
            return {
                organization_id: node.value,
                organization_name: node.text,
                package_id: packageId
            };
        });
        this.model.set({
            name: el.find('[name="name"]').val(),
            feeds: feeds,
            reports: reports,
            organizations: orgs
        });
    },

    close: function() {
        this.remove();
    },

    submit: function() {
        this.updateModel(); // apply form data to model
        this.model.save();
        this.collection.add(this.model);
        this.close();
    }
});