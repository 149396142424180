MarketReportsApp.Views.ReportBuilderStep2 = Backbone.View.extend({

	events: {
		"click .primary-select-all": "primarySelectAll",
		"click .secondary-select-all": "secondarySelectAll",
        "change .range-type": "rangeTypeChange",
        "click .view-trigger": "tabChange",
        "click .create-new-list": "newList",
        "change .js-switch": "listToggle",
        "click .close": "clearModalX",
        "click .save-list": "clearModalSave"
	},

    initialize: function() {

		var self = this;

		$.ajax({
			url: '/assets/js/market-reports/report-builder/templates/report-builder-step-2.html',
			success: function(extTemplate) {
				self.template = _.template(extTemplate);
				$('#step2').append(self.$el);
			},
            cache:true
		});
    },
    
    render: function() {
        let model = MarketReportsApp.Variables.primaryModel.toJSON();

		this.$el.html(this.template({primaryModel: this.model.toJSON(), reportModel: MarketReportsApp.Variables.reportBuilderModel.toJSON()}));

        let minimumInputLength = 2;
        if (model.primary_dimension_type == 'Stock Ticker v2') {
            $minimumInputLength = 1;
        }

		if (model.primary_dimension_type != "None") {
			$('.primary-select').select2({
				placeholder: "Select a " + model.primary_dimension_type,
				ajax: {
					url: function(params) {
						return "/api/reports/dimensions/autocomplete/id/" + model.primary_dimension_type_id + "/" + params.term;
					},
					delay: 250
				},
				minimumInputLength: minimumInputLength
			});
		}

		if (model.secondary_dimension_type != "All") {

            var displayId = '';
            if (model.secondary_dimension_type == 'Company') {
                displayId = '?displayId';
            }

            minimumInputLength = 2;
            if (model.secondary_dimension_type == 'Stock Ticker v2') {
                minimumInputLength = 1;
            }

            $('.secondary-select').select2({
                placeholder: "Select a " + model.secondary_dimension_type,
				ajax: {
					url: function(params) {
						return "/api/reports/dimensions/autocomplete/id/" + model.secondary_dimension_type_id + "/" + params.term + displayId;
					},
					delay: 250
				},
				minimumInputLength: minimumInputLength
			});
		}

		var startDate = new Date(model.startDate);
		var endDate = new Date(model.endDate);

        switch (model.aggregate_period_type.toLowerCase()) {
            case "daily":
                $('.input-daterange').datepicker({
                    "startDate": startDate,
                    "endDate": endDate
                });
                break;
            case "weekly":
                $('.input-daterange').datepicker({
                    "startDate": startDate,
                    "endDate": endDate,
                    "daysOfWeekDisabled": [1,2,3,4,5,6]
                });
                break;
            case "offset monthly":
                $('.input-daterange').datepicker({
                    "format": "mm/16/yyyy",
                    "startDate": startDate,
                    "endDate": endDate,
                    "minViewMode": "months"
                });
                break;
            case "monthly":
            case "quarterly":
                $('.input-daterange').datepicker({
                    "startDate": startDate,
                    "endDate": endDate,
                    "minViewMode": 1
                });
                break;
            case "yearly":
                $('.input-daterange').datepicker({
                    "startDate": startDate,
                    "endDate": endDate,
                    "minViewMode": 2
                });
                break;
        }

        if (MarketReportsApp.Variables.EditReport) {
            this.prepopulate();
        }

        var elems = $('.js-switch');
        _.each(elems, function(elem) {
            new Switchery(elem, { color: '#3A6E8F' });
        });

        MarketReportsApp.Utils.updateDimensionList(this.model, MarketReportsApp.Variables.reportBuilderModel.get('orgId')[0]);
	},

    rangeTypeChange: function() {
        $('.input-daterange').toggleClass('hidden');
        $('.relative-daterange').toggleClass('hidden');
        $('.last-num-periods').toggleClass('required');
    },

	primarySelectAll: function() {
		if ($('.primary-select').attr('disabled') === undefined) {
			$('.primary-select').prop('disabled', true);
		} else {
			$('.primary-select').removeAttr('disabled');
		}
		$('.primary-select').toggleClass('required');
		$('.primary-select').valid();
	},

	secondarySelectAll: function() {
		if ($('.secondary-select').attr('disabled') === undefined) {
			$('.secondary-select').prop('disabled', true);
		} else {
			$('.secondary-select').removeAttr('disabled');
		}
		$('.secondary-select').toggleClass('required');
		$('.secondary-select').valid();
    },
    
    listToggle: function(e) {
        var elem = $(e.target);

        if (elem.data('dimension') == 'primary' && elem.is(':checked')) {
            $('.primary-list-toggle').toggleClass('hidden');
        } else if (elem.data('dimension') == 'primary' && !elem.is(':checked')) {
            $('.primary-list-toggle').toggleClass('hidden');
        } else if (elem.data('dimension') == 'secondary' && elem.is(':checked')) {
            $('.secondary-list-toggle').toggleClass('hidden');
        } else if (elem.data('dimension') == 'secondary' && !elem.is(':checked')) {
            $('.secondary-list-toggle').toggleClass('hidden');
        } else {
            console.error("Something really weird is happening...what did you do?");
        }
    },

    clearModalSave: function() {
        this.newListModel.parseModel({aggregateModel: this.model});
        this.newListModel.destroy();
        this.newListView.remove();
    },

    clearModalX: function() {
        this.newListModel.destroy();
        this.newListView.remove();
    },

    newList: function() {
        let dimensionTypeSelections = [];

        if (MarketReportsApp.Variables.DimensionTypeIdsForSelectedAggregate.primary_dimension_type != "None")
            dimensionTypeSelections.push({id: MarketReportsApp.Variables.DimensionTypeIdsForSelectedAggregate.primary_dimension_type_id, dimension_type: MarketReportsApp.Variables.DimensionTypeIdsForSelectedAggregate.primary_dimension_type});
        if (MarketReportsApp.Variables.DimensionTypeIdsForSelectedAggregate.secondary_dimension_type != "None")
            dimensionTypeSelections.push({id: MarketReportsApp.Variables.DimensionTypeIdsForSelectedAggregate.secondary_dimension_type_id, dimension_type: MarketReportsApp.Variables.DimensionTypeIdsForSelectedAggregate.secondary_dimension_type});

        this.newListModel = new MarketReportsApp.Models.DimensionValues();
        this.newListView = new MarketReportsApp.Views.DimensionValuesAddEdit({ model: this.newListModel, dimensionTypeSelections: dimensionTypeSelections });
    },

	prepopulate: function() {
        var self = this;

        if (MarketReportsApp.Variables.EditReport.reportComposite.sheets[0].aggregateTable.primary_dimension_value == "all") {
            $('.primary-select-all').click();
        } else {
            $('.existing-primary-dimension').removeClass('hidden');

            var primaryDimValues = [];
            var primaryDimensionType = MarketReportsApp.Variables.EditReport.reportComposite.sheets[0].aggregateTable.primary_dimension_type_id;

            if (MarketReportsApp.Variables.EditReport.reportComposite.sheets[0].aggregateTable.primary_dimension_value == 'list') {
                $('.primary-list-set').click();
                MarketReportsApp.Variables.PrimaryListId = MarketReportsApp.Variables.EditReport.reportComposite.report.primary_dimension_value_list_id;
            } else {
                MarketReportsApp.Variables.EditReport.reportComposite.sheets.forEach(function (sheet) {
                    primaryDimValues.push(sheet.aggregateTable.primary_dimension_value);
                });

                $.ajax({
                    type: 'POST',
                    url: "/api/reports/dimensions/dimensionNameString/" + primaryDimensionType,
                    data: { dimensionValues: primaryDimValues },
                    success: function (response) {
                        _.forEach(response.dimensionNames, function (dimension) {
                            let option = $('<option selected="selected"></option>').val(dimension.dimensionValue).text(dimension.dimensionName);
                            $('.primary-select').append(option).trigger('change');
                        });
                    }
                });
            }
        }

        if (MarketReportsApp.Variables.EditReport.reportComposite.sheets[0].secondaryDimensions[0].secondary_dimension_value == "all") {
            $('.secondary-select-all').click();
        } else {
            $('.existing-secondary-dimension').removeClass('hidden');

            var secondaryDimValues = [];
            var secondaryDimensionType = MarketReportsApp.Variables.EditReport.reportComposite.sheets[0].aggregateTable.secondary_dimension_type_id;

            if (MarketReportsApp.Variables.EditReport.reportComposite.sheets[0].secondaryDimensions[0].secondary_dimension_value == 'list') {
                $('.secondary-list-set').click();
                MarketReportsApp.Variables.SecondaryListId = MarketReportsApp.Variables.EditReport.reportComposite.report.secondary_dimension_value_list_id;
            } else {
                MarketReportsApp.Variables.EditReport.reportComposite.sheets[0].secondaryDimensions.forEach(function (dimension) {
                    secondaryDimValues.push(dimension.secondary_dimension_value);
                });

                $.ajax({
                    type: 'POST',
                    url: "/api/reports/dimensions/dimensionNameString/" + secondaryDimensionType,
                    data: { dimensionValues: secondaryDimValues },
                    success: function (response) {
                        _.forEach(response.dimensionNames, function (dimension) {
                            let option = $('<option selected="selected"></option>').val(dimension.dimensionValue).text(dimension.dimensionName);
                            $('.secondary-select').append(option).trigger('change');
                        });
                    }
                });
            }            
        }


        if (MarketReportsApp.Variables.EditReport.reportComposite.sheets[0].reportTable.aggregate_period_start) {
            $('.start-date').val(moment(MarketReportsApp.Variables.EditReport.reportComposite.sheets[0].reportTable.aggregate_period_start).format('MM/DD/YYYY'));
            var endDateString = (moment(MarketReportsApp.Variables.EditReport.reportComposite.sheets[0].reportTable.aggregate_period_end).format('MM/DD/YYYY'));
            if(endDateString == 'Invalid date') {
                endDateString = '';
            }
            $('.end-date').val(endDateString);

        } else {
            $('.range-type').val("relative").change();
            $('.last-num-periods-start').val(MarketReportsApp.Variables.EditReport.reportComposite.sheets[0].reportTable.last_num_aggregate_periods_start);
            $('.last-num-periods-end').val(MarketReportsApp.Variables.EditReport.reportComposite.sheets[0].reportTable.last_num_aggregate_periods_end);
        }
	}

});