CompanyListApp.Models.CompanyModel = Backbone.Model.extend({

    defaults: {
        list_id:            null,
        linkup_company_id:  null,
        stock_key:          null,
        stock_ticker:       null,
        start_timestamp:    null,
        end_timestamp:      null,
        is_rollup_parent:   false,
        company_identifier_id: null,
        parent_id:          null,
        tr_org_id:          null,
        parent_tr_org_id:   null,
        company_name:       null,
        ats:                null,
        num_jobs:           null
    },

    toJSON: function() {
        return {
            id:                 this.attributes.id,
            list_id:            this.attributes.list_id,
            linkup_company_id:  this.attributes.linkup_company_id,
            attributes: {
                stock_key:              this.attributes.stock_key,
                stock_ticker:           this.attributes.stock_ticker,
                start_timestamp:        this.convertToTimestamp(this.attributes.start_timestamp || null),
                end_timestamp:          this.convertToTimestamp(this.attributes.end_timestamp || null),
                is_rollup_parent:       this.attributes.is_rollup_parent,
                company_identifier_id:  this.attributes.company_identifier_id,
                parent_id:              this.attributes.parent_id
            },
            identifiers: {
                stock_ticker:           this.attributes.stock_ticker,
                tr_org_id:              this.attributes.tr_org_id,
                parent_tr_org_id:       this.attributes.parent_tr_org_id
            }
        };
    },

    parse: function(data) {
        let result = {
            list_id: CompanyListApp.Variables.listId,
            is_rollup_parent: CompanyListApp.Variables.isRollupParent,
            parent_id: CompanyListApp.Variables.parentId
        };
        result = CompanyListApp.Utilities.flattenObject(data, result);

        result.start_timestamp = this.convertToDateString(result.start_timestamp);
        result.end_timestamp = this.convertToDateString(result.end_timestamp);
        return result;
    },

    convertToTimestamp: function(dateString) {
        return moment.tz(dateString, 'UTC').unix() || null;
    },

    convertToDateString: function(timeStamp) {
        if (typeof timeStamp !== 'number') {
            return timeStamp;
        }
        return moment.unix(timeStamp).utc().format('YYYY-MM-DD');
    },

    idAttribute: "id",
    urlRoot: "/api/company-list/company"
});


CompanyListApp.Models.CompanyCollection = Backbone.Collection.extend({
    model: CompanyListApp.Models.CompanyModel,

    parse: function(data) {
        var result = [];
        _.each(data, function(item) {
            var formattedItem = CompanyListApp.Utilities.flattenObject(item, {});
            result.push(formattedItem);
        });
        return result;
    }
});
