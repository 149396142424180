MarketReportsApp.Views.ConstraintView = Backbone.View.extend({

	className: "input-group m-b",

	events: {
		"click .remove-constraint": "removeConstraint"
	},

	initialize: function() {
		this.template = _.template(MarketReportsApp.Templates.ConstraintTemplate)
		this.render();
	},

	render: function() {
		this.$el.html(this.template(MarketReportsApp.Variables.Constraints));
		$('.constraints-container').append(this.$el);
	},

	removeConstraint: function() {
		if ($('.constraints-container > .input-group').length == 2) {
			$('.remove-constraint').prop("disabled", "true");
		}

		this.remove();
	}

});