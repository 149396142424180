FeedsApp.Models.FeedHistory = Backbone.Model.extend({
    parse: function(data) {
        if (data.stats && window.Intl && typeof window.Intl === 'object') {
            if (data.stats.total_num_jobs != null) {
                data.stats.total_num_jobs = new Intl.NumberFormat('en-US').format(data.stats.total_num_jobs);
            } else {
                data.stats.total_num_jobs = 'n/a';
            }
            if (data.stats.num_jobs_deleted != null) {
                data.stats.num_jobs_deleted = new Intl.NumberFormat('en-US').format(data.stats.num_jobs_deleted);
            } else {
                data.stats.num_jobs_deleted = 'n/a';
            }
            if (data.stats.num_jobs_inserted != null) {
                data.stats.num_jobs_inserted = new Intl.NumberFormat('en-US').format(data.stats.num_jobs_inserted);
            } else {
                data.stats.num_jobs_inserted = 'n/a';
            }
            if (data.stats.num_jobs_updated != null) {
                data.stats.num_jobs_updated = new Intl.NumberFormat('en-US').format(data.stats.num_jobs_updated);
            } else {
                data.stats.num_jobs_updated = 'n/a';
            }
        }
        return data;

    }
});

FeedsApp.Models.FeedStatus = Backbone.Model.extend({
    urlRoot: '/api/queue/notification/feed',
    defaults: {
        "id": null,
        "queued_entity_type": null,
        "queued_entity_id": null,
        "queued_entity_name": null,
        "user_id": null,
        "org_id": null,
        "current_status": null,
        "current_status_timestamp": null,
        "status_history": {}
    }
});

FeedsApp.Collections.FeedHistoryItems = Backbone.Collection.extend({
    initialize: function(models, options) {
        this.feedId = options.id;
    },
    url: function () {
        return '/api/feeds/details/history/' + this.feedId; // needs to be made
    },
    model: FeedsApp.Models.FeedHistory
});