CompanyListApp.Views.MissingChildrenRow = Backbone.View.extend({

    tagName: "tr",
    className: "missing-children-row",

    events: {
        'click button.add-subsidiary': 'addSubsidiary'
    },

    initialize: function() {
        var self = this;
        self.listenTo(self.model, 'change', self.render);
        self.listenTo(self.model, 'destroy', self.remove);
        $('table.missing-children tbody').append(self.$el);
        self.render();
    },

    render: function() {
        this.$el.html(CompanyListApp.Templates.MissingChildrenRow(this.model.attributes));
        return this;
    },

    addSubsidiary: function() {
        new CompanyListApp.Views.AddCompany({model: this.model});
        $('html, body').scrollTop($('div.add-new-company').offset().top);
        this.deleteCompany();
    },

    deleteCompany: function() {
        this.model.destroy();
    }
});