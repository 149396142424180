FeedsApp.Views.FeedBuilderStep5 = Backbone.View.extend({

    events: {
        "click .add-field-filter": "addFilter"
    },

    initialize: function() {
        this.collection = this.model.get('feed_filters');
        var self = this;
        $.ajax({
            url: '/assets/js/feeds/feed-builder/templates/step5.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.render();

                $.ajax({
                    url: '/assets/js/feeds/feed-builder/templates/filter-template.html',
                    success: function(extTemplate) {
                        FeedsApp.Templates.FilterTemplate = _.template(extTemplate);
                        self.collection.each(function(filter) {
                            new FeedsApp.Views.FieldFilter({model: filter});
                        });
                    },
                    dataType: 'html',
                    cache: false
                });
                $.ajax({
                    url: '/assets/js/feeds/feed-builder/templates/filter-modal.html',
                    success: function(extTemplate) {
                        FeedsApp.Templates.FilterModal = _.template(extTemplate);
                    },
                    dataType: 'html',
                    cache: false
                });

            },
            dataType: 'html',
            cache: false
        });

    },

    render: function() {
        this.$el.html(this.template());
        $('#step5').html(this.$el);
        let selectedFeedType = $('.feed-type-select option:selected').text().trim();
        if (selectedFeedType === "Historical") {
            $('.filter-historical-only').removeClass('hidden');
            $('.filter-not-historical').addClass('hidden');
        } else {
            $('.filter-historical-only').addClass('hidden');
            $('.filter-not-historical').removeClass('hidden');
        }
        return this;
    },

    addFilter: function() {
        if ($('.filters-dropdown').val() !== "Select One") {

            // Combine JobFieldList and StructuredFieldList
            var combinedFieldList = FeedsApp.Variables.JobFieldList.concat(FeedsApp.Variables.StructuredFieldList);

            // Filter the combined list to find the selected field
            var availableField = combinedFieldList.filter(function(field) {
                return field.id === $('.filters-dropdown').val();
            })[0];

            if (availableField) {  // Ensure availableField is found
                var model = new FeedsApp.Models.FeedFilter();
                model.get('primary_available_field').set('internal_field_id', availableField.id)
                    .set('internal_name', availableField.internal_name);
                model.setPrimaryConditionByName('equals', FeedsApp.Variables.ConditionTypes);

                this.collection.add(model);
                FeedsApp.Variables.CurrentFilterView = new FeedsApp.Views.FieldFilter({ model: model });
            }
        }
    }

});