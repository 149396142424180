FeedsApp.Models.SelectedOrg = Backbone.Model.extend({

    url: function() {
        return '/api/feeds/associations/org/' + FeedsApp.Variables.SelectedOrg
    },

    defaults: {
        id: FeedsApp.Variables.SelectedOrg,
        name: null,
        activeAssociations: []
    },

    parse: function(data) {
        var model = {};

        model.name = data.name;
        model.activeAssociations = data.activeAssociations;

        return model;
    },

    submit: function() {
        this.save(null,
            {
                success: function (model, response) {
                    toastr["success"]("Updates complete!");
                    window.location = '/feeds/organization-access';
                },
                error: function (model, response) {
                    toastr["error"](response.responseJSON.message, response.responseJSON.status);
                },
                parse: false
            })
    }



});