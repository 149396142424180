var WhatsRunningApp = new (Backbone.Router.extend({
    Models: {},
    Views: {},
    Collections: {},
    Variables: {},
    Templates: {},

    routes: {
        "admin/whats-running": "startWhatsRunningPage"
    },

    startWhatsRunningPage: function() {
        var feedsModel = new WhatsRunningApp.Models.Feeds();
        new WhatsRunningApp.Views.Feeds({ model: feedsModel });

        var reportsModel = new WhatsRunningApp.Models.Reports();
        new WhatsRunningApp.Views.Reports({ model: reportsModel });

        var ppModel = new WhatsRunningApp.Models.PostProcess();
        new WhatsRunningApp.Views.PostProcess({ model: ppModel });
    }
}));