FeedsApp.Views.FeedActions = Backbone.View.extend({

    events: {
        "click .delete-feed-btn": "deleteFeed"
    },

    initialize: function() {
        var self = this;
        $.ajax({
            url: '/assets/js/feeds/history/templates/feed-actions.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
            },
            cache:true
        });
    },

    render: function() {
        this.$el.html(this.template(this.model.toJSON()));
        $('.feed-actions-container').append(this.$el);
    },

    deleteFeed: function() {

        $('.deleting').toggleClass('hidden');
        $('.modal-footer').addClass('hidden');

        var self = this;
        $.ajax({
            url: self.model.get("delete_link"),
            type: 'DELETE',
            success: function () {
                toastr["success"]("Feed has been deleted.");
                window.location = '/feeds';
            },
            error: function() {
                toastr["error"]("Feed failed to delete.");
            }
        });
    }
});