FeedsApp.Views.SelectedOrgView = Backbone.View.extend({

    events: {
        "click .selectAll": 'selectAllGroupRadios',
        "click .saveButton": "updateAssociations"
    },

    initialize: function() {
        this.model = new FeedsApp.Models.SelectedOrg;
        var self = this;
        $.ajax({
            url: '/assets/js/feeds/organization-access/templates/selected-org-view.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
            },
            cache:true
        });

        $('.selected-organization-container').append(this.$el);
    },

    render: function() {
        this.$el.html(this.template(this.model.toJSON()));
    },

    refresh: function() {
        this.model.clear();
        var self = this;

        this.model.fetch({
            success: function() {
                self.render();
            }
        });
    },

    selectAllGroupRadios: function(e) {
        var grouping = $(e.currentTarget).val();

        $('input:radio.' + grouping).each(function(){
            if (!$(this).prop('checked')) {
                $(this).prop('checked', true).addClass("changed");
            }
        });
    },

    updateAssociations: function() {
        // Clears the old list of associations
        var self = this;
        self.model.set({activeAssociations: []});

        // Send the new list of enabled associations to the backend
        var newActiveAssociations = [];
        $('input:radio').each(function() {
            if ($(this).prop('checked') && !$(this).hasClass('selectAll')) {
                if ($(this).hasClass('enableFeed')) {
                    newActiveAssociations.push({organization_id: FeedsApp.Variables.SelectedOrg, feed_id: $(this).attr('value')})
                }
            }
        });
        self.model.set({activeAssociations: newActiveAssociations});
        self.model.submit();
    }

});