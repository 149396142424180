FeedsApp.Models.OrgAssociation = FeedsApp.Models.FeedsBase.extend({

    defaults: {
        feed_id:null,
        organization_id:null,
        organization_name:null
    }

});

FeedsApp.Collections.OrgAssociations = Backbone.Collection.extend({

    model: FeedsApp.Models.OrgAssociation

});