DataPackagesApp.Views.PackageManager = DynamicViewSpecialCollectionEdition.extend({
    containerSelector: '.package-manager-app-container',
    templateUrl: '/assets/js/data-packages/package-builder/templates/package-manager.html',
    createNewModelView: function(model, collection, options) {
        return new DataPackagesApp.Views.Package({
            model: model,
            collection: this.collection
        });
    },

    events: {
        "click .add-new-button": "openEditModal"
    },

    initialize: function() {
        DynamicViewSpecialCollectionEdition.prototype.initialize.apply(this, arguments);
    },

    openEditModal: function() {
        new DataPackagesApp.Views.EditPackage({
            model: new DataPackagesApp.Models.PackageModel(),
            collection: this.collection
        });
    }
});