MarketReportsApp.Views.FilterBuilderView = Backbone.View.extend({

	className: 'row',

	events: {
		"click .add-constraint": "addConstraint"
	},

	initialize: function() {
		var self = this;

		$.ajax({
			url: '/assets/js/market-reports/filter-builder/templates/filter-builder.html',
			success: function(extTemplate) {
				self.template = _.template(extTemplate);
				self.render();
			},
            cache: false
		});

		$.ajax({
			url: '/assets/js/market-reports/filter-builder/templates/constraint.html',
			success: function(constraintTemplate) {
				MarketReportsApp.Templates.ConstraintTemplate = constraintTemplate;
				self.addConstraint();
				$('.remove-constraint').prop('disabled', 'true');
			},
            cache: false
		});
	},

	render: function() {
		this.$el.html(this.template());
		$('.mr-filter-builder-app-container').append(this.$el);
	},

	addConstraint: function() {
		new MarketReportsApp.Views.ConstraintView();

		if ($('.constraints-container > .input-group').length > 1)
			$('.remove-constraint').removeAttr('disabled');
	}
});