ProductRolesApp.Views.UserRoles = Backbone.View.extend({

    events: {

    },

    initialize: function() {
        var self = this;

        $.ajax({
            url: '/assets/js/product-roles/product-roles/templates/user-role.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
            },
            async: false,
            cache: false
        });
    },

    render: function() {
        $('.jde-user-roles-app-container').empty();
        this.$el.html(this.template(this.model.toJSON()));
        $('.jde-user-roles-app-container').append(this.$el);
    }

});
