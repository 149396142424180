FeedsApp.Views.FeedRow = Backbone.View.extend({

    tagName: 'tr',

    initialize: function() {
        $('.feeds-admin-table').append(this.$el);
        this.render();
    },

    render: function() {
        this.$el.html(FeedsApp.Templates.FeedRow(this.model.toJSON()));
    }

});