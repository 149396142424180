var HomePageApp = new (Backbone.Router.extend({
    Models: {},
    Views: {},
    Collections: {},
    Variables: {},
    Templates: {},

    routes: {
        "home/": "startHomePage"
    },

    startHomePage: function() {
        var createdStatsModel = new HomePageApp.Models.Stats({ id: 'created' });
        var deletedStatsModel = new HomePageApp.Models.Stats({ id: 'deleted' });
        var activeStatsModel = new HomePageApp.Models.Stats({ id: 'active' });
        var chartModel = new HomePageApp.Models.Chart();

        new HomePageApp.Views.Stats({ model: createdStatsModel });
        new HomePageApp.Views.Stats({ model: deletedStatsModel });
        new HomePageApp.Views.Stats({ model: activeStatsModel });
        new HomePageApp.Views.Chart({ model: chartModel });

        var userRoles = user.get('productRoles');

        if (userRoles.indexOf('ROLE_APP_FEEDS') > -1) {
            var feedsModel = new HomePageApp.Models.RecentFeeds();
            new HomePageApp.Views.RecentFeeds({ model: feedsModel });
        }

        if (userRoles.indexOf('ROLE_APP_MARKETREPORTS') > -1) {
            var mrModel = new HomePageApp.Models.RecentReports();
            new HomePageApp.Views.RecentReports({ model: mrModel });

            var ppModel = new HomePageApp.Models.RecentPPReports();
            new HomePageApp.Views.RecentPPReports({ model: ppModel });
        }
    }
}));
