MarketReportsApp.Views.ReportBuilderStep1 = Backbone.View.extend({

	events: {
		"change .aggregate-select": "renderOptions",
		"change .aggregate-filter": "filterAggregates",
		"click .reset-aggregate-filters": "resetAggregateFilters",
		"click .org-select-wrapper": "informUserOrgSettings",
		"click .ftp-directory-name-wrapper": "informUserRenameBehavior",
		"click #dist-channel > button": "toggleDistributionChannels",
		"change #standard-checkbox": "standardizeDistributionChannels",
	},

    initialize: function(options) {

		this.parent = options.parent;

		this.filteredAggregates = MarketReportsApp.Variables.Aggregates;
		this.appliedFilters = {};

		let self = this;

		$.ajax({
			url: '/assets/js/market-reports/report-builder/templates/report-builder-step-1.html',
			success: function(extTemplate) {
				self.template = _.template(extTemplate);
				$('#step1').append(self.$el);
				self.render();
				self.initPlugins();
			},
            cache:true
		});

		this.primaryView = new MarketReportsApp.Views.ReportBuilderPrimaryView({model: MarketReportsApp.Variables.primaryModel});
		MarketReportsApp.Variables.DimensionTypeIdsForSelectedAggregate = this.primaryView.getDimensionTypeId();
    },

    render: function() {

		let model = this.model.toJSON();

		this.$el.html(this.template(model));

		if (MarketReportsApp.Variables.EditReport) {
			this.parent.populateMetricOptions();
			this.renderOptions();
			$('.format').val(model.format);
			if (MarketReportsApp.Variables.EditReport.reportComposite.report.compression_type == null) {
				$('.file-compression-checkbox').prop('checked', false).trigger('change');
			}
		}
		$('.available-aggregates').text(_.size(MarketReportsApp.Variables.Aggregates));
	},

	initPlugins: function() {
		$('.org-select').select2({
			placeholder: "Select an Organization",
			ajax: {
				url: function(params) {
					return "/api/users/organization/autocomplete/" + params.term;
				},
				delay: 250
			},
			minimumInputLength: 2
		});

		this.model.set({ orgId: $('.org-select').val() });
	},

	renderOptions: function() {

		if(MarketReportsApp.Variables.EditReport){
			MarketReportsApp.Variables.aggregateID = this.model.get('aggregateId');
		} else {
			MarketReportsApp.Variables.aggregateID = $('.metric-select').val();
		}
		$('.report-builder-form').validate({
			errorPlacement: function (error, element) {
				if ($(element).hasClass('last-num-periods'))
					element.parent().before(error);
				else
					element.before(error);
			}
		});
	},

    filterAggregates: function(filter) {

		$('.aggregate-select, .metric-select').empty();
		$('.aggregate-select, .metric-select').append("<option disabled selected>Select One</option>");

		let category = filter.target.name;
		let value = filter.target.options[filter.target.selectedIndex].value;

		// reset the filters if user decides to circle back on a filter that is already set.
		if(this.appliedFilters[category] !== undefined){
			delete this.appliedFilters[category];
			this.resetAggregateFilters(this.appliedFilters); // a flag to determine the path
		}
		if (value.length !== 0) { // do not filter on "No Selection"
			this.appliedFilters[category] = value;
			this.filteredAggregates = _.filter(this.filteredAggregates, function(aggregate) {
				return value === aggregate[0][category];
			});
		}
		this.filteredAggregates.forEach(function(aggregate){
			$('.aggregate-select').append('<option value="' + aggregate[0].name + '">' + aggregate[0].name +'</option>');
		});
		$('.available-aggregates').text(_.size(this.filteredAggregates));
		$('.available-metrics').text('');
	},

	resetAggregateFilters: function(filters) {

		let self = this;

		this.filteredAggregates = MarketReportsApp.Variables.Aggregates;

		if(!filters.target && Object.keys(this.appliedFilters).length > 0){ // reset the filters using existing filters.
			_.each(this.appliedFilters, function(value, category){
				self.filteredAggregates = _.filter(self.filteredAggregates, function(aggregate) {
					return value === aggregate[0][category];
				});
			});
			return; // finish filtering with the newest value
		}

		let aggregateFilters = document.getElementsByClassName('aggregate-filter');
		for(let i = 0; i < aggregateFilters.length; i++){
			aggregateFilters[i].selectedIndex = 0;
		}

		$('.aggregate-select, .metric-select').empty();
		$('.aggregate-select, .metric-select').append("<option disabled selected>Select One</option>");
		document.getElementById('metric').disabled = true;

		_.each(MarketReportsApp.Variables.Aggregates, function(aggregate){
			$('.aggregate-select').append('<option value="' + aggregate[0].name + '">' + aggregate[0].name +'</option>');
		});
		$('.available-aggregates').text(_.size(MarketReportsApp.Variables.Aggregates));
		$('.available-metrics').text('');
	},

	informUserOrgSettings: function() {
		if (MarketReportsApp.Variables.EditReport != null) {
			if (!MarketReportsApp.Variables.EditReport.reportComposite.report.is_standard) {
				toastr["error"]("Cannot change organization for non-standard reports. You must create a new report.");
			}
		}
	},
	toggleDistributionChannels: function(e) {

		e.currentTarget.classList.toggle('btn-default');
		e.currentTarget.classList.toggle('btn-primary');
		let clickedElement = e.currentTarget.childNodes[1];
		clickedElement.classList.toggle('glyphicon-unchecked');
		clickedElement.classList.toggle('glyphicon-check');

		let channelDiv = document.getElementById('dist-channel');
		let checkedChannels = channelDiv.getElementsByClassName('glyphicon-check');

		let modelChannels = this.model.get('distribution_channels');

		// Check if the clicked channel is unchecked
		if (e.currentTarget.childNodes[1].classList.contains('glyphicon-unchecked')) {
			let uncheckedChannelValue = e.currentTarget.value;
			let channelIndex = modelChannels.indexOf(uncheckedChannelValue);

			if (channelIndex !== -1) {
				// Remove the unchecked channel from the model channels
				modelChannels.splice(channelIndex, 1);
			}
		}

		// Update model channels with checked channels
		modelChannels = Array.from(checkedChannels).map(function(checkedChannel) {
			return checkedChannel.parentElement.value;
		});

		// Set the updated channels array to the model
		this.model.set({ distribution_channels: modelChannels });


	},

	standardizeDistributionChannels: function(e) {
		let val = e.currentTarget.checked;
		let distDiv = $('#dist-channel');
		distDiv.children(':button').each(function(index, element) {
			let el = $(element);
			let span = $(element.children[0]);
			if(val) {
				//order matters, has to be "enabled" for click event
				if(span.hasClass('glyphicon-unchecked')) {
					if (el.prop('value') !== 'brickftp') {
						el.trigger('click');
					}
				}
				else {
					if (el.prop('value') === 'brickftp') {
						el.trigger('click');  // Uncheck BrickFTP for standard reports
					}
				}
				el.prop('disabled', true);
			} else {
				//order matters, has to be "enabled" for click event
				el.prop('disabled', false);
				if(span.hasClass('glyphicon-check')) {
					el.trigger('click');
				}
			}
			element.classList.toggle('disabled');
		});
	},
});
