MarketReportsApp.Models.DimensionValues = Backbone.Model.extend({
    urlRoot: "/api/reports/dimensions/lists/",

    defaults: {
        id: null,
        org_id: [],
        name: null,
        dimension_type_id: null,
        dimension_type_name: null,
        is_standard: 0,
        values: []
    },

    parse: function(listData) {
            var model = {};

            model.id = listData.id;
            model.name = listData.name;
            model.is_standard = listData.is_standard;

            if (listData.dimension_type_id) {
                model.dimension_type_id = listData.dimension_type_id;
            }
            if (listData.dimension_type_name) {
                model.dimension_type_name = listData.dimension_type_name;
            }


            var orgArray = [];
            _.forEach(MarketReportsApp.Variables.ListOrgAssociations, function (association) {
                orgArray.push(association.org_id);
            });
            model.org_id = orgArray;

            return model;
    },

    parseModel: function(options) {
        let orgId = this.get("org_id");

        this.set({ name: $('.model-field').val() });
        this.set({ dimension_type_id: $('.dimension-type-select').val() });
        if (!this.get("is_standard")) {
            this.set({ org_id: parseInt($('.list-org-select').val()) });
        } else {
            var orgArray = [];
            _.forEach($('.list-org-select:visible').val(), function (value) {
                orgArray.push(value);
            });
            this.set({ org_id: orgArray });
        }

        var newValuesArray = [];
        _.forEach($('.dimension-value-list').val(), function (value) {
            var itemModel = new MarketReportsApp.Models.DimensionValueListItem;
            itemModel.set({value: value});
            newValuesArray.push(itemModel);
        });
        this.set({ values: newValuesArray });

        this.save(null, {
            success: function(model, response) {
                if(MarketReportsApp.Variables.InReportBuilder){ // creating a new list in modal during report builder step 2
                    MarketReportsApp.Variables.newListId = response.id;
                    MarketReportsApp.Utils.updateDimensionList(options.aggregateModel, orgId);
                } else {
                    window.location = '/market-reports/dimension-values'; // creating a new list from "my lists"
                }
            },
            error: function(model, response) {
                toastr["error"](response.responseJSON.message, response.responseJSON.status);
            }
        });
    }
});

MarketReportsApp.Models.DimensionValueListItem = Backbone.Model.extend({
    defaults: {
        id: null,
        list_id: null,
        value: null
    }
});

MarketReportsApp.Collections.DimensionValuesListsCollection = Backbone.Collection.extend({
    initialize: function(models, options) {
        this.orgId = options.orgId;
    },
    url: function () {
        return '/api/reports/dimensions/lists/collections/org/' + this.orgId;
    },
    model: MarketReportsApp.Models.DimensionValues
});