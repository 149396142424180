MarketReportsApp.Views.ReportDetailsView = Backbone.View.extend({

    initialize: function() {

        var self = this;
        $.ajax({
            url: '/assets/js/market-reports/report-details/templates/report-details.html',
            success: function(extTemplate){
                self.template = _.template(extTemplate);
                self.render();
            },
            cache: false
        });
    },

    render: function() {
        this.$el.html(this.template(this.model.toJSON()));
        $('.report-details-container').append(this.$el);
    }
});