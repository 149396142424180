MarketReportsApp.Views.SelectedOrgView = Backbone.View.extend({

    events: {
        "click #view-changes": "viewChanges",
        "click #clearChanges": "clearChanges",
        "click #saveChanges": "saveChanges",

        'change input[type=radio]': 'changedRadio',
        "click .selectAll": 'selectAllGroupRadios',
        "click .updated-item": "toggleUpdatedItem",

        "click #closeConfirmationModal": "closeConfirmationModal",
        "click #discardChangesYes": "discardChangesYes",
        "click #discardChangesNo": "discardChangesNo",
        "click #closeOrganizationModal": "confirmDiscardChanges",
        "click .close": "confirmDiscardChanges",

        "draw.dt .selected-organization-table": "syncUiWithModels"
    },

    dataTable : null,

    initialize: function() {
        this.model = new MarketReportsApp.Models.SelectedOrg;
        this.modelsToUpdate = new MarketReportsApp.Collections.UpdatedAssociations;
        this.removedModels = new MarketReportsApp.Collections.UpdatedAssociations;
        this.enabledItemIds = [];

        var self = this;
        $.ajax({
            url: '/assets/js/market-reports/organization-access/templates/selected-org.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
            },
            cache:true
        });

        $('.selected-organization').append(this.$el);
    },

    render: function() {
        let modelJson = this.model.toJSON();
        this.$el.html(this.template(modelJson));
        this.enabledItemIds = modelJson['enabledItemIds'];

        $('.sk-organization').addClass('hidden');
        this.dataTable = $('.selected-organization-table').DataTable({
            limit: 10,
            dom: '<"top pull-left"fil>rt<"bottom pull-left"p>',
            compact: true,
            columns: [{orderable: true}, {orderable: false}, {orderable: false}]
        });
        this.setViewChangesButtonAttributes();
        $('#aggregates-tab').click();
    },

    refresh: function() {
        this.model.clear();
        self = this;
        this.model.fetch({
            success: function() {
                self.render();
            }
        });
    },

    clearChanges: function() {
        this.modelsToUpdate.reset();
        this.removedModels.reset();
        this.closeConfirmationModal();
        this.refresh();
    },

    closeConfirmationModal: function() {
        $('#confirmation').modal('hide');
    },

    syncUiWithModels: function(e) {

        var self = this;

        $(e.currentTarget).closest('.selected-organization-table').find('.hasModel').each( function() {
            let info = JSON.parse(this.dataset.info);
            let updatePending = false;
            self.modelsToUpdate.forEach(model => {
                if ($(this).attr('name') === model.get('genericId')) {
                    if (info.action === model.get('action')) {
                        $(this).prop('checked', true);
                    }
                    updatePending = true;
                }
            });
            if (!updatePending) {
                self.enabledItemIds.forEach(itemId => {
                    if ($(this).attr('name') === itemId) {
                        if (info.action === 'save') {
                            $(this).prop('checked', true);
                        }
                        else {
                            $(this).prop('checked', false);
                        }
                    }
                })
            }
        });
    },

    checkedItem: function(e) {
        $(e.currentTarget).prop('checked', true);

        let modelUpdate = this.createModelFromRadio(e.currentTarget);
        let enabledOnServer = this.enabledItemIds.includes(modelUpdate.get('genericId'));
        let actionIsEnable = modelUpdate.get('action') === 'save';

        let previousAction = this.modelsToUpdate.findWhere({genericId:modelUpdate.get('genericId')});
        if (previousAction) {
            if (previousAction.get('action') !== modelUpdate.get('action')) {
                // This is the opposite action.
                this.modelsToUpdate.remove(previousAction);
            }
        }
        else if (enabledOnServer !== actionIsEnable) {
            this.modelsToUpdate.add(modelUpdate);
        }
        this.setViewChangesButtonAttributes();
    },

    setViewChangesButtonAttributes: function () {

        var viewChangesElement = $('#view-changes');
        if (this.modelsToUpdate.length === 0) {
            viewChangesElement.prop('disabled', true);
            viewChangesElement.text('View Changes');
        }
        else {
            viewChangesElement.prop('disabled', false);
            viewChangesElement.text(`View Changes (${this.modelsToUpdate.length})`)
        }
    },

    changedRadio: function(e) {

        e.preventDefault();

        if (!$(e.currentTarget).hasClass('selectAll')) {
            this.checkedItem(e);
            // Clear select all buttons when individual item is selected
            $(e.currentTarget).closest('.selected-organization-table').find('.selectAll').prop('checked', false);
        }
    },

    selectAllGroupRadios: function(e) {

        var grouping = $(e.currentTarget).val();
        var self = this;

        $(this.dataTable.rows().nodes()).find('input:radio.' + grouping).each(function(){
            this.currentTarget = this;
            self.checkedItem(this);
        });
    },

    viewChanges: function() {

        this.removedModels.reset();
        $('.updated-items').empty();
        let noChanges = this.modelsToUpdate.length === 0;
        $('#saveChanges').prop('disabled', noChanges);
        $('#clearChanges').prop('disabled', noChanges);
        $('#noChangesMessage').prop('hidden', !noChanges)

        let categorizedModels = this.modelsToUpdate.groupBy(function (modelToUpdate) {
            return modelToUpdate.get('category');
        });

        Object.keys(categorizedModels).forEach(function (key, index) {

            let categoryTitle = // for display purposes
                key === 'org-to-aggregate' ? 'Aggregates'
                    : key === 'org-to-report' ? 'Reports'
                    : key === 'org-to-list' ? 'Lists'
                        : key === 'org-to-computation' ? 'Computations'
                            : 'none';
            $('.updated-items').append('<h4 id="' + key + '">' + categoryTitle + '</h4>');

            categorizedModels[key].forEach(function (item) {

                let action = item.get('action');
                let userAction = // for display purposes
                    action === 'delete' ? 'Disable'
                        : action === 'save' ? 'Enable'
                        : 'none';

                $('#' + key + '').after('<a class="updated-item" style="display:block;">' + item.get('name') +
                    '<span id= "' + item.get('dom_identifier') + '" class="pull-right updated-item">' + userAction + '  </span></a>');
            });
        });
    },

    toggleUpdatedItem: function(e) {

        if(e.currentTarget.classList.contains('remove-item')){
            let targetedModel = this.removedModels.where({ dom_identifier: e.currentTarget.childNodes[1].id });
            this.removedModels.remove(targetedModel);
        } else {
            let targetedModel = this.modelsToUpdate.where({ dom_identifier: e.currentTarget.childNodes[1].id });
            this.removedModels.add(targetedModel);
        }
        e.currentTarget.classList.toggle('remove-item');

        $('#saveChanges').prop('disabled', (this.modelsToUpdate.length - this.removedModels.length) === 0);
    },

    saveChanges: function() {

        let self = this;

        this.removedModels.forEach(removedModel => {
            this.modelsToUpdate.remove(removedModel);
        });

        this.modelsToUpdate.forEach(function(modelToUpdate){

            $('#'+ modelToUpdate.get('dom_identifier') +'').append('' +
                '<div class="sk-spinner sk-spinner-circle sk-updating-item" style="display:inline-block">' +
                '<div class="sk-circle1 sk-circle"></div><div class="sk-circle2 sk-circle"></div><div class="sk-circle3 sk-circle"></div>' +
                '<div class="sk-circle4 sk-circle"></div><div class="sk-circle5 sk-circle"></div><div class="sk-circle6 sk-circle"></div>' +
                '<div class="sk-circle7 sk-circle"></div><div class="sk-circle8 sk-circle"></div><div class="sk-circle9 sk-circle"></div>' +
                '<div class="sk-circle10 sk-circle"></div><div class="sk-circle11 sk-circle"></div><div class="sk-circle12 sk-circle"></div></div>');

            modelToUpdate.save(null, {
                success: function(model, response) {
                    $('#'+ model.get('dom_identifier') +' > .sk-spinner').remove();
                    $('#'+ model.get('dom_identifier') +'').append('  <i class="fa fa-check"></i>');
                    self.checkAllSavesComplete();
                },
                error: function(model, response) {
                    toastr["error"]("Warning: updated association " + response.responseJSON.data + " failed to save.");
                }
            });
        });
    },

    checkAllSavesComplete: function() {

        if($('.sk-updating-item').length === 0){
            $('#saveChanges').prop('disabled', true);
            toastr["success"]("All updates complete!");
            this.modelsToUpdate.reset();
            this.removedModels.reset();
            this.refresh();
            setTimeout(this.closeConfirmationModal, 1000, this);
        }
    },

    discardChangesYes: function(e) {
        this.clearChanges();
        this.closeOrganizationModal();
    },

    discardChangesNo: function(e) {
        $('#discardChanges').modal('hide');
    },

    confirmDiscardChanges: function(e) {
        e.preventDefault();
        if (this.modelsToUpdate.length > 0) {
            $('#discardChanges').modal('show');
        }
        else {
            this.closeOrganizationModal();
        }
        return false;
    },

    closeOrganizationModal: function() {
        this.$el.empty();
        $('.modal').modal('hide');
        $('.modal-backdrop').remove();
    },

    createModelFromRadio: function(element) {
        if (!element.dataset.info) return null;

        let radioInfo = JSON.parse(element.dataset.info);

        let keyName =
            radioInfo.category === 'org-to-aggregate' ? 'aggregation_schedule_id'
                :   radioInfo.category === 'org-to-report' ? 'report_id'
                :   radioInfo.category === 'org-to-list' ? 'list_id'
                    :   radioInfo.category === 'org-to-computation' ? 'computation_id'
                        :   'none';

        let model = new MarketReportsApp.Models.UpdatedAssociation({
            [keyName]: element.name,
            genericId: element.name,
            name: radioInfo.name,
            url: '/api/reports/' + radioInfo.category + '/' + radioInfo.action,
            org_id: MarketReportsApp.Variables.SelectedOrg,
            organization_id: MarketReportsApp.Variables.SelectedOrg, //todo some factories require 'organization' and some are 'org'. Fix that in the backend.
            category: radioInfo.category,
            action: radioInfo.action,
            dom_identifier: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)
        });
        return model;
    }
});
