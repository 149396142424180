CompanyListApp.Views.MissingChildrensTable = Backbone.View.extend({

    initialize: function() {
        var self = this;
        $.ajax({
            url: '/assets/js/company-list/templates/missing-childrens-table.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
                $('div.company-list').append(self.$el);

                $.ajax({
                    url: '/assets/js/company-list/templates/missing-children-row.html',
                    success: function(extTemplate) {
                        CompanyListApp.Templates.MissingChildrenRow = _.template(extTemplate);
                        self.render();
                    },
                    dataType: 'html',
                    cache: false
                });

            },
            dataType: 'html',
            cache: false
        });

    },

    render: function() {
        var self = this;
        this.$el.html(this.template());
        this.collection.forEach(self.createRowView, this);
        return this;
    },

    createRowView: function(companyModel) {
        new CompanyListApp.Views.MissingChildrenRow({model: companyModel, id: companyModel.attributes.id});
    }

});