FeedsApp.Models.PostProcessWebhook = Backbone.Model.extend({

    defaults: function () {
        return {
            id: null,
            user_id: user.get('userId'),
            url: null,
            method: null,
            data: null,
            headers: null,
        }
    },

    parse: function(data) {

        var webhook_model = {};

        webhook_model.user_id = data['user_id'];
        webhook_model.id = data['id'];
        webhook_model.url = data['url'];
        webhook_model.method = data['method'];
        webhook_model.data = data['data'];
        webhook_model.headers = data['headers'];

        return webhook_model;
    }
});

FeedsApp.Collections.PostProcessWebhook = Backbone.Collection.extend({

    model: FeedsApp.Models.PostProcessWebhook

});