var FeedsApp = new (Backbone.Router.extend({
	Models: {},
	Views: {},
	Collections: {},
	Variables: {
		SelectedOrg: {}
	},
	Templates: {},

	routes: {
		"feeds/details/:feedId": "startHistory",
		"feeds/nu-details/:feedId": "nuStartHistory",
	},

	initialize: function(options){
	},

	startFeedAdmin: function(orgId, orgName, userId) {
		if (FeedsApp.Variables.SelectedOrg && FeedsApp.Variables.SelectedOrg.orgId) {
			orgId = FeedsApp.Variables.SelectedOrg.orgId;
			orgName = FeedsApp.Variables.SelectedOrg.orgName;
		}

		var collection = new FeedsApp.Collections.FeedsManage([], {orgId: orgId});
		collection.fetch({
			success: function() {
				 new FeedsApp.Views.FeedsManage(
					{
						collection: collection,
						orgId: orgId,
						orgName: orgName,
						userId: userId,
						templates: {table: 'feeds.html', row: 'feed-row.html'}
					}
				);
			}
		});
	},

	startHistory: function(feedId) {
		var definitionModel = new FeedsApp.Models.FeedsDefinition({ id: feedId });
		var feedAssociations = new FeedsApp.Models.SelectedFeed();

		FeedsApp.Variables.SelectedFeed = feedId;
		feedAssociations.fetch({
			error: function() {
				window.location.href = '/feeds';
			}
		});

		definitionModel.fetch({
			success: function() {
				new FeedsApp.Views.FeedDefinition({ model: definitionModel, associationsModel: feedAssociations });
				new FeedsApp.Views.FeedActions({ model: definitionModel });
                new FeedsApp.Views.FeedBreadcrumb({ model: definitionModel });
			},
			error: function() {
				window.location.href = '/feeds';
			}
		});

        var feedHistoryCollection = new FeedsApp.Collections.FeedHistoryItems([], { id: feedId });
        feedHistoryCollection.fetch({
            success: function() {
                new FeedsApp.Views.FeedHistory(
                    {
                        collection: feedHistoryCollection
                    }
                );
            }
        });
    },

	nuStartHistory: function(feedId) {
        var definitionModel = new FeedsApp.Models.FeedsDefinition({ id: feedId });
        definitionModel.fetch({
			success: function() {
				if (definitionModel.get('template').name === 'Raw Archive Template') {
                    $('.feed-definition').hide();
				}
			}
		});
        new FeedsApp.Views.NuFeedDefinition({ model: definitionModel });
        new FeedsApp.Views.NuFeedActions({ model: definitionModel });

        var statusModel = new FeedsApp.Models.FeedStatus({id: feedId });
        statusModel.fetch();
        new FeedsApp.Views.NuFeedBreadcrumb({ model: statusModel });

        var feedHistoryCollection = new FeedsApp.Collections.FeedHistoryItems([], { id: feedId });
        feedHistoryCollection.fetch();
        new FeedsApp.Views.NuFeedHistory({collection: feedHistoryCollection});
	},

	startBuilder: function(feedData) {
		var model = new FeedsApp.Models.FeedModel(feedData, {parse: true});
		this.Variables.feedBuilderView = new FeedsApp.Views.FeedBuilderWizardView({model: model});
	},

	startOrgAccess: function() {
        new FeedsApp.Views.OrganizationAccessView();
	},

	loadFromLocalStorage: function() {
		if(FeedsApp.Variables.UserType && FeedsApp.Variables.UserType === "internal") {
			let settings = JSON.parse(window.localStorage.getItem('user-settings'));
			if (settings && settings.selectedOrg) {
				FeedsApp.Variables.SelectedOrg = settings.selectedOrg;
			}
		}
	},

	updateSelectedOrg: function(orgId, orgName) {
		if (FeedsApp.Variables.UserType && FeedsApp.Variables.UserType === "internal") {
			let settings = JSON.parse(window.localStorage.getItem('user-settings'));
			const org = {orgId: orgId, orgName: orgName};
			!!settings ? settings.selectedOrg = org : settings = {selectedOrg: org};

			window.localStorage.setItem('user-settings', JSON.stringify(settings));
			FeedsApp.Variables.SelectedOrg = org;
		}
	},

	Utils: {
		covertMoneyInputToInt: function(input) {
			let newString = input.replace("$", "");

			if (newString.indexOf('.') === -1) { // TODO if there are NO decimals, assume the number represents a whole dollar amount and return that?
				return parseInt(newString + "00");
			}

			let decimalPosition = newString.indexOf('.');
			let length = newString.length;
			if (decimalPosition !== length - 3) { // adjust down to two decimal places. doesn't round.
				newString = newString.slice(0, decimalPosition) + "." + newString.slice(decimalPosition + 1, decimalPosition + 3);
			}

			let charactersAfterDecimal = newString.split(".")[1].length;
			if (charactersAfterDecimal !== 2) { // if there is only one decimal place, append 0 to get two
				newString = newString + "0";
			}

			newString = parseInt(newString.replace(".", ""));
			return newString;
		},
	}
}));