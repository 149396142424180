FeedsApp.Views.NuFeedBreadcrumb = ReadOnlyViewStandardModelEdition.extend({
    containerSelector: '.feed-breadcrumb-container',
    templateUrl: '/assets/js/feeds/history/templates/feed-breadcrumb.html',
    events: {
        "click .my-feeds": "myFeeds"
    },

    myFeeds: function() {
        window.location = '/feeds/'
    }
});