HomePageApp.Models.RecentFeeds = Backbone.Model.extend({
    
    url: function() {
        var orgId = user.get('orgId');
        return "/api/feeds/" + orgId + "?sort=last_generated_date"
    },
    
    defaults: {
        feeds: []
    },

    parse: function (data) {
        var model = {};
        data = data.slice(0,10);
        model.feeds = data;
        return model;
    }

});