FeedsApp.Views.FeedBuilderStep4 = Backbone.View.extend({

    events: {
        "change .template-select": "globalChange"
    },

    setModelTemplateId: function(value) {
        this.model.setModelAttribute('template.id', value);
    },

	initialize: function() {
		var self = this;
		$.ajax({
			url: '/assets/js/feeds/feed-builder/templates/step4.html',
			success: function(extTemplate) {
				self.template = _.template(extTemplate);
				$('#step4').append(self.$el);
				self.render();
				self.previewWindowView = new FeedsApp.Views.Step4PreviewWindow({model: self.model});
			},
			dataType: 'html',
            cache: false
		});
	},

	render: function() {
    	var self = this;
		this.$el.html(this.template(self.model.toJSON()));
	},

    globalChange: function() {
    	var val = $('.template-select').val();
        FeedsApp.Variables.GlobalDefinition = $.grep(FeedsApp.Variables.GlobalTemplates, function (e) {
            return e.id === val;
        })[0];
		this.setModelTemplateId(val);
        this.previewWindowView.render();
    }
});