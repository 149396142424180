FeedsApp.Views.FieldCustomize = Backbone.View.extend({

    className: function() {
        return "row m-b " + this.model.get("field_type") + "-field-customize";
    },

    events: {
        "click .remove-field": "clear",
        "change .input-group [name]": "updateModel",
        "hidden.bs.modal": "resetModal",
    },

    initialize: function(options) {

        this.parent = options.parent;

        if (this.model.get('field_type') === 'global') {
            $('.global-customization').append(this.$el);
        } else {
            $('.job-customization').append(this.$el);
        }
        //this.listenTo(this.model, 'change', this.render);
        this.render();

        var id = "#" + this.model.attributes.internal_field_id;
        if (this.model.attributes.internal_name !== 'extended') {
            $(id).addClass('disabled');
        }

        this.modalRowViews = [];

    },

    render: function() {

        var self = this;

        this.$el.html(FeedsApp.Templates.FieldCustomizeTemplate(this.model.toJSON()));

        return this;
    },

    clear: function() {
        FeedsApp.Variables.feedBuilderView.step3.collection.remove(this.model);
        var id = "#" + this.model.attributes.internal_field_id;
        $(id).removeClass('disabled');
        this.remove();
    },

    resetModal: function() {

        _.each(this.modalRowViews, function(rowView){
            rowView.unbind();
            rowView.remove();
        });
    },

    updateModel: function(e) {
        if (e.target.type === "checkbox") {
            this.model.setModelAttribute(e.target.name, e.target.checked);
        } else {
            this.model.setModelAttribute(e.target.name, e.target.value);
        }
    }

});