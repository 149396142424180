var MarketReportsApp = new (Backbone.Router.extend({
    Models: {},
    Views: {},
    Collections: {},
    Variables: {
        SelectedOrg: {}
    },
    Templates: {},

    routes: {
        "market-reports/details/:reportId": "startDetails",
        "market-reports/report-builder": "startReportBuilder",
        "market-reports/report-builder/edit/:id": "startReportBuilderEdit",
        // "market-reports/my-reports": "startMyReports",
        "market-reports/aggregate-builder": "startAggregateBuilder",
        "market-reports/aggregate-registry": "startAggregateRegistry",
        "market-reports/filter-builder": "startFilterBuilder",
        "market-reports/organization-access": "startOrgAccess",
        // "market-reports/dimension-values": "startDimensionValueTable", // called from Twig. Sorry for the ugly, kids
        "market-reports/dimension-values/add": "startDimensionValueAddEdit",
        "market-reports/dimension-values/edit/:id": "startDimensionValueAddEdit"
    },

    startDetails: function(reportId) {
        // var model = new Backbone.Model();

        // model.set({
        // 	selectModels: new this.Models.MarketReports(reports),
        // 	historyModels: new this.Models.ReportsHistory()
        // });

        // var marketReportsView = new MarketReportsApp.Views.MarketReportsView({model: model});

        MarketReportsApp.Variables.ReportID = reportId;
        // MarketReportsApp.Variables.SelectOrganizationId = orgId;

        var model = new MarketReportsApp.Models.ReportsHistory();
        model.fetch({
            success: function() {
                new MarketReportsApp.Views.ReportBreadcrumbView({ model: model });
                new MarketReportsApp.Views.ReportHistoryView({ model: model });
                new MarketReportsApp.Views.ReportDetailsView({ model: model });
                new MarketReportsApp.Views.ReportActionsView({ model: model });
            },
            error: function() {
                window.location.href = '/market-reports/my-reports';
            }
        })
    },

    startReportBuilder: function() {
        var model = new MarketReportsApp.Models.ReportBuilderModel();
        var reportBuilderView = new MarketReportsApp.Views.ReportBuilderView({ model: model });
        MarketReportsApp.Variables.InReportBuilder = true;
    },

    startReportBuilderEdit: function() {
        var model = new MarketReportsApp.Models.ReportBuilderModel(MarketReportsApp.Variables.EditReport, {parse: true});
        var reportBuilderView = new MarketReportsApp.Views.ReportBuilderView({ model: model });
        MarketReportsApp.Variables.InReportBuilder = true;
    },

    startAggregateBuilder: function() {
        var model = new Backbone.Model(MarketReportsApp.Variables.aggregateBuilderData);
        var aggregateBuilderView = new MarketReportsApp.Views.AggregateBuilderView({ model: model });
    },

    startAggregateRegistry: function() {
        const aggregateRegistry = new MarketReportsApp.Collections.AggregateRegistry;
        const orgId = user.get('orgId');
        aggregateRegistry.fetch({
            data: { orgId },
            success: function() {
               new MarketReportsApp.Views.AggregateRegistryView({ collection: aggregateRegistry });
           },
            error: function(model, response) {
                toastr["error"](response.responseJSON.message, response.responseJSON.status);
            }
        });
    },

    startFilterBuilder: function() {
        new MarketReportsApp.Views.FilterBuilderView();
    },

    startOrgAccess: function() {
        new MarketReportsApp.Views.OrganizationAccessView();
    },

    startScheduledReports: function() {
        new MarketReportsApp.Views.ScheduledReportsView();
    },

    startDimensionValueTable: function(orgId, orgName) {
        if(MarketReportsApp.Variables.SelectedOrg.orgId) {
            orgId = MarketReportsApp.Variables.SelectedOrg.orgId;
            orgName = MarketReportsApp.Variables.SelectedOrg.orgName;
        }

        var collection = new MarketReportsApp.Collections.DimensionValuesListsCollection([], {orgId: orgId});

        $.ajax({
            url: '/api/reports/dimensions/all',
            success: function (data) {
                MarketReportsApp.Variables.DimensionTypes = data;
            },
            cache: false
        });

        collection.fetch({
            success: function() {
                new MarketReportsApp.Views.DimensionValues(
                    {
                        collection: collection,
                        orgId: orgId,
                        orgName: orgName
                    }
                );
            }
        });
    },

    startDimensionValueAddEdit: function() {
        if (MarketReportsApp.Variables.DimensionValueListData) {
            var model = new MarketReportsApp.Models.DimensionValues(MarketReportsApp.Variables.DimensionValueListData, {parse: true});
            model.set({ values: MarketReportsApp.Variables.DimensionValueListItemData })
        } else {
            var model = new MarketReportsApp.Models.DimensionValues();
        }

        new MarketReportsApp.Views.DimensionValuesAddEdit({ model: model });
    },

    startMyReports: function(orgId, orgName, userId) {
        if(MarketReportsApp.Variables.SelectedOrg.orgId) {
            orgId = MarketReportsApp.Variables.SelectedOrg.orgId;
            orgName = MarketReportsApp.Variables.SelectedOrg.orgName;
        }

        var collection = new MarketReportsApp.Collections.NuMyReports([], {orgId: orgId});
        collection.fetch({
            success: function() {
                new MarketReportsApp.Views.NuMyReports(
                    {
                        collection: collection,
                        orgId: orgId,
                        orgName: orgName,
                        userId: userId
                    }
                );
            }
        });
    },

    Utils: {
        toggleLoader: function() {
            $('.loader').toggleClass('hidden');
        },

        // TODO rather than hit the same API twice, can't we just send the aggregate to one and use it to send back both lists?
        updateDimensionList: function(aggregate, orgId) {
            aggregate =  aggregate.toJSON();

            $('.primary-dimension-lists').empty();
            $('.secondary-dimension-lists').empty();

            $.ajax({
                url: '/api/reports/dimensions/lists/available-lists',
                type: 'get',
                data: {
                    org_id: orgId,
                    dimension_type_id: aggregate.primary_dimension_type_id
                },
                success: function (data) {
                    $('.primary-dimension-lists').append('<option selected value="">Select One</option>');

                    _.each(data, function (list) {
                        var selected = "";
                        if (MarketReportsApp.Variables.PrimaryListId == list.id || MarketReportsApp.Variables.newListId == list.id)
                            selected = "selected";

                        $('.primary-dimension-lists').append('<option value="' + list.id + '" ' + selected + '>' + list.name + '</option>').trigger('change');
                    });
                }
            });

            $.ajax({
                url: '/api/reports/dimensions/lists/available-lists',
                type: 'get',
                data: {
                    org_id: orgId,
                    dimension_type_id: aggregate.secondary_dimension_type_id
                },
                success: function (data) {
                    $('.secondary-dimension-lists').append('<option selected value="">Select One</option>');
                    _.each(data, function (list) {
                        var selected = "";
                        if (MarketReportsApp.Variables.SecondaryListId == list.id || MarketReportsApp.Variables.newListId == list.id)
                            selected = " selected";

                        $('.secondary-dimension-lists').append('<option value="' + list.id + '"' + selected + '>' + list.name + '</option>').trigger('change');
                    });
                }
            });
        },

        loadFromLocalStorage: function() {
            if(MarketReportsApp.Variables.UserType && MarketReportsApp.Variables.UserType === "internal") {
                let settings = JSON.parse(window.localStorage.getItem('user-settings'));
                if (settings && settings.selectedOrg) {
                    MarketReportsApp.Variables.SelectedOrg = settings.selectedOrg;
                }
            }
        },

        updateSelectedOrg: function(orgId, orgName) {
            if (MarketReportsApp.Variables.UserType && MarketReportsApp.Variables.UserType === "internal") {
                let settings = JSON.parse(window.localStorage.getItem('user-settings'));
                const org = {orgId: orgId, orgName: orgName};
                !!settings ? settings.selectedOrg = org : settings = {selectedOrg: org};

                window.localStorage.setItem('user-settings', JSON.stringify(settings));
                MarketReportsApp.Variables.SelectedOrg = org;
            }
        },

        sortAlphabetic: function(data) {
            data.sort(function(a, b) {
                var dimA = a.dimension_type.toUpperCase();
                var dimB = b.dimension_type.toUpperCase();
                if (dimA < dimB) {
                    return -1;
                } else if (dimB < dimA) {
                    return 1;
                }
                return 0;
            });
            return data;
        }
    }
}));