MarketReportsApp.Views.ReportBuilderStep4 = Backbone.View.extend({

    events: {
        "change .schedule-select": function() {
            this.toggleUserNotify();
            this.checkScheduleType();
        },
        "change .post-process-schedule-select": function() {
            this.togglePostProcessUserNotify();
            this.checkPostProcessScheduleType();
        }
    },

    initialize: function() {
        var self = this;

        $.ajax({
            url: '/assets/js/market-reports/report-builder/templates/report-builder-step-4.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
                $('#step4').append(self.$el);
            },
            cache:true
        });
    },

    render: function() {

        var model = this.model.toJSON();
        this.$el.html(this.template(this.model.toJSON()));
        if (MarketReportsApp.Variables.EditReport) {
            this.prepopulate();
        }
    },

    initPlugins: function () {
        $('.user-select').select2({
            ajax: {
                url: function(params) {
                    return "/api/users/market-reports/autocomplete/" + $('.single-org-select').val() + "/" + params.term;
                },
                delay: 250
            },
            minimumInputLength: 2
        });
    },


    toggleUserNotify: function() {

        var self = this;
        if (($('.notify-users').hasClass('hidden') && $('.schedule-select').val() != 'noSchedule') || (!$('.notify-users').hasClass('hidden') && $('.schedule-select').val() == 'noSchedule')) {
            $('.notify-users').toggleClass('hidden');
            self.initPlugins();
        }
    },

    checkScheduleType: function() {

        if (($('.schedule-options').hasClass('hidden') && $('.schedule-select').val() == 'monthly') || (!$('.schedule-options').hasClass('hidden') && $('.schedule-select').val() != 'monthly')) {
            $('.schedule-options').toggleClass('hidden');
        }
    },

    checkPostProcessScheduleType: function() {

        if (($('.post-process-schedule-options').hasClass('hidden') && $('.post-process-schedule-select').val() == 'monthly') || (!$('.post-process-schedule-options').hasClass('hidden') && $('.post-process-schedule-select').val() != 'monthly')) {
            $('.post-process-schedule-options').toggleClass('hidden');
        }
    },


    togglePostProcessUserNotify: function() {
        var self = this;
        if (($('.post-process-notify-users').hasClass('hidden') && $('.post-process-schedule-select').val() != 'noSchedule') || (!$('.post-process-notify-users').hasClass('hidden') && $('.post-process-schedule-select').val() == 'noSchedule')) {
            $('.post-process-notify-users').toggleClass('hidden');
            self.initPlugins();
        }
    },

    prepopulate: function() {
        if (MarketReportsApp.Variables.EditReport.reportSchedule) {
            $('.schedule-select').val(MarketReportsApp.Variables.EditReport.reportSchedule.frequency).change();
            if(MarketReportsApp.Variables.EditReport.reportSchedule.frequency === 'monthly'){
                if(MarketReportsApp.Variables.EditReport.reportSchedule.day_to_run === 'last'){
                    $('input[name="last-day-checkbox"]').prop("checked", true);
                    $('.day-value-select').val('').prop('disabled', true);
                } else {
                    $('input[name="schedule-option-value"]').val(MarketReportsApp.Variables.EditReport.reportSchedule.day_to_run);
                }

            }
            JSON.parse(MarketReportsApp.Variables.EditReport.reportSchedule.send_to_addresses).forEach(function(address) {
                var option = $('<option selected="selected"></option>').val(address).text(address);
                $('.report-schedule-user-select').append(option).trigger('change');
            });
        }

        if (MarketReportsApp.Variables.EditReport.postProcessSchedule) {
            $('.post-process-schedule-select').val(MarketReportsApp.Variables.EditReport.postProcessSchedule.frequency).change();
            if(MarketReportsApp.Variables.EditReport.postProcessSchedule.frequency === 'monthly'){
                if(MarketReportsApp.Variables.EditReport.postProcessSchedule.day_to_run === 'last'){
                    $('input[name="post-process-last-day-checkbox"]').prop("checked", true);
                    $('.post-process-day-value-select').val('').prop('disabled', true);
                } else {
                    $('input[name="post-process-schedule-option-value"]').val(MarketReportsApp.Variables.EditReport.postProcessSchedule.day_to_run);
                }

            }
            JSON.parse(MarketReportsApp.Variables.EditReport.postProcessSchedule.send_to_addresses).forEach(function(address) {
                var option = $('<option selected="selected"></option>').val(address).text(address);
                $('.post-process-user-select').append(option).trigger('change');
            });
        }
    }
});