FeedsApp.Views.FeedHistoryRow = Backbone.View.extend({

    tagName: 'tr',

    initialize: function() {
        $('.feed-history-table').append(this.$el);
        this.render();
    },

    render: function() {
        this.$el.html(FeedsApp.Templates.FeedHistoryRow(this.model.toJSON()));
        $("[rel='tooltip']").tooltip();
    }
});