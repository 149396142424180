FeedsApp.Views.FeedBuilderStep2 = Backbone.View.extend({

    events: {
        "change .feed-type-select": "feedTypeCheck"
    },

    initialize: function() {
        let self = this;
        $.ajax({
            url: '/assets/js/feeds/feed-builder/templates/step2.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
                $('#step2').append(self.$el);
                self.render();
            },
            dataType: 'html',
            cache: false
        });
    },

    render: function() {
        this.$el.html(this.template(this.model.toJSON()));
        this.feedTypeCheck();
    },

    feedTypeCheck: function() {
        let selectedFeedType = $('.feed-type-select option:selected').text().trim();
        this.model.set("feed_type", selectedFeedType);

        if (selectedFeedType === "Organic" && this.model.get('id') === null) {
            this.addOrganicFilters();
        }
        else if (selectedFeedType === "Historical" && this.model.get('id') === null) {
            this.removeOrganicFilters();
        }
        //this is dumb that it has to be in both step 2 and step 3
        if (selectedFeedType === "Historical") {
            $('.field-historical-only').removeClass('disabled');
            $('.field-not-historical').addClass('disabled');
        } else {
            $('.field-historical-only').addClass('disabled');
            $('.field-not-historical').removeClass('disabled');
        }
    },

    addOrganicFilters: function() {
        let excludedAtses = 'appcast,appcast-cpc,applytothisjob,applytothisjobnew,clickcast,clickcastcmp,clickcastprivateexchange,clickcastpxca,clickcastshaker,lfdata,recruitingcpc,ripple,ripplejoveo,rrjoveo'.split(',');

        let filterCollection = new FeedsApp.Collections.FeedFilters();

        let atsFilter = new FeedsApp.Models.FeedFilter;
        atsFilter.setPrimaryInternalFieldByName('ats', FeedsApp.Variables.JobFieldList);
        atsFilter.setPrimaryConditionByName('is in', FeedsApp.Variables.ConditionTypes);
        atsFilter.set('mode', 'exclude');
        atsFilter.set('primary_value', excludedAtses);

        let siteFilter = new FeedsApp.Models.FeedFilter;
        siteFilter.setPrimaryInternalFieldByName('site', FeedsApp.Variables.JobFieldList);
        siteFilter.setPrimaryConditionByName('equals', FeedsApp.Variables.ConditionTypes);
        siteFilter.set('mode', 'include');
        siteFilter.set('primary_value', ['1']);

        filterCollection.add(atsFilter);
        filterCollection.add(siteFilter);

        this.model.set('feed_filters', filterCollection);
    },

    removeOrganicFilters: function() {
        this.model.set('feed_filters', new FeedsApp.Collections.FeedFilters());
    }
});