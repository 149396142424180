MarketReportsApp.Views.ReportHistoryView = Backbone.View.extend({
    
    tagName: 'div',
    className: 'row',

    events: {
        "click .delete-prompt": "setDelete",
        "click .delete-button": "deleteReport"
    },

	initialize: function() {
		var self = this;
		$.ajax({
			url: '/assets/js/market-reports/history/templates/report-history.html',
			success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
			},
            cache: false
		});

		$('.mr-history-app-container').append(this.$el);
	},

	render: function() {
		this.$el.html(this.template(this.model.toJSON()));
	},

    setDelete: function(e) {
        e.preventDefault();
        MarketReportsApp.Variables.DeleteReportId = $(e.currentTarget).attr("id");
    },

    deleteReport: function() {
        $.ajax({
            type: 'POST',
            url:  '/api/reports/delete',
            data: {report_id: MarketReportsApp.Variables.DeleteReportId, is_archived: true},
            success: function(model, response) {
                toastr["success"]("Report has been deleted.");
                window.location = '/market-reports/my-reports';
            },
            error: function(model, response) {
                toastr["error"]("Report failed to delete.");
            },
        });
    }
});