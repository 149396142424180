FeedsApp.Views.NuFeedHistory = ReadOnlyViewSpecialCollectionEdition.extend({
    containerSelector: '.feed-history-container',
    templateUrl: '/assets/js/feeds/history/templates/feed-history.html',

    createNewModelView: function(model) {
        return new FeedsApp.Views.NuFeedHistoryRow({model: model});
    },

    events: {
		"click .view-stats": "viewHistory",
        "click .delete-button": "deleteFeed"
	},

    // Dear user,
    // TypeError: l is undefined.
    // TypeError: l is undefined.
    // TypeError: l is undefined.
    // TypeError: l is undefined.
    // TypeError: l is undefined.
    // Sincerely,
    // Javascript.
    setUpDataTable: function() {
        this.$el.find('table').DataTable({
            pageLength:10,
            targets: 'noSort',
            bSort: false,
            order: []
        });
    },

	deleteFeed: function() {
        var feedId = FeedsApp.Variables.feedID;

        $.ajax({
			url: '/api/feeds/' + feedId,
			type: 'DELETE',
			success: function () {
                toastr["success"]("Feed has been deleted.");
                window.location = '/feeds';
			},
            error: function() {
                toastr["error"]("Feed failed to delete.");
            }
		});
	}
});