MarketReportsApp.Views.ReportHistoryRow = Backbone.View.extend({

    tagName: 'tr',

    initialize: function() {
        $('.report-history-table').append(this.$el);
        this.render();
    },

    render: function() {
        this.$el.html(MarketReportsApp.Templates.ReportHistoryRow(this.model));
    }
});