MarketReportsApp.Views.AppendedDataView = Backbone.View.extend({

    className: "m-b ibox-content appended-data",

    events: {
        "click .remove": "preRemove"
    },

    initialize: function() {
        $('.appended-container').append(this.$el);
        this.render();
    },

    render: function() {
        this.$el.html(MarketReportsApp.Templates.AppendedData(this.model.toJSON()));
    },

    preRemove: function() {
        this.remove();

        var self = this;
        MarketReportsApp.Variables.AppendedDimensionViews = MarketReportsApp.Variables.AppendedDimensionViews.filter(function(view) {
            return view.cid !== self.cid;
        });
    }
});