FeedsApp.Models.ScheduledFeeds = Backbone.Model.extend({

    defaults: function () {
        return {
            id: null,
            users_id: user.get('userId'),
            frequency: null,
            send_to_addresses: '[]',
            day_to_run: null,
            hour_to_run: null,
            interval: null,
            last_queued_time: null,
            last_completed_time: null
        }

    },

    parse: function(data) {

        var schedule_model = {};

        schedule_model.users_id = data['users_id'];
        // If we are in edit, the value of the frequency is 'intradaily'. 
        if(data['frequency'] == 'intradaily'){
            schedule_model.frequency = 'interval';
        } else {
            schedule_model.frequency = data['frequency'];
        }
        // It needs to be set back to 'interval' to successfully save to the backend.
        schedule_model.id = data['id'];
        schedule_model.send_to_addresses = data['send_to_addresses'];
        schedule_model.day_to_run = data['day_to_run'];
        schedule_model.hour_to_run = data['hour_to_run'];
        schedule_model.interval = data['interval'];
        schedule_model.last_queued_time = data['last_queued_time'];
        schedule_model.last_completed_time = data['last_completed_time'];

        return schedule_model;
    }
});

FeedsApp.Collections.ScheduledFeeds = Backbone.Collection.extend({

    model: FeedsApp.Models.ScheduledFeeds

});