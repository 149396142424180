HomePageApp.Views.RecentFeeds = Backbone.View.extend({
    className: "col-lg-4",

    initialize: function() {
        var self = this;

        $('.user-specific-container').append(this.$el);

        $.ajax({
			url: '/assets/js/homepage/03_templates/recent-feeds.html',
			success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
                self.model.fetch();
			},
			cache: false
        });
        
        this.model.on('change', this.render, this);
    },

    render: function() {
        var model = this.model.toJSON();
        this.$el.html(this.template(model));

        if (model.feeds.length > 0) {
            $('.recent-feeds').toggleClass('sk-loading');
        }
    }
});
