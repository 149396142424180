FeedsApp.Views.FieldFilterModal = Backbone.View.extend({

    className: "modal-filter-view",

    events: {
        "change .input-group [name]": "updateModel",
        "change .form-group [name]": "updateModel",
        "click .confirm": "submit",
        "click .close": "close"
    },

    initialize: function(options) {
        this.parent = options.parent;

        $('.filters-container').append(this.$el);
        this.render();
    },

    render: function() {
        this.$el.html(FeedsApp.Templates.FilterModal(this.parent.model.toJSON()));
    },

    updateModelOld: function(e) {
        var value = e.target.value.trim();
        if ((e.target.name === 'primary_value' || e.target.name === 'secondary_value') && value.match(/[\n,]+/)) {
            value = value.split(/[\n,]+/);
        }
        this.parent.model.set(e.target.name, value);
    },

    updateModel: function(e) {
        if (e.target.name === 'primary_value') {
            this.parent.model.set('primary_value', e.target.value.trim().split(/[\n,]+/));
        } else if (e.target.name === 'secondary_value') {
            this.parent.model.set('secondary_value', e.target.value.trim().split(/[\n,]+/));
        } else if (e.target.name === 'primary_condition_type_id') {
            this.parent.model.get('primary_condition').set("id", e.target.value);
            this.parent.model.get('primary_condition').set("name", this.$el.find('.primary-condition-type option:selected').text());
        } else if (e.target.name === 'secondary_condition_type_id') {
            this.parent.model.get('secondary_condition').set("id", e.target.value);
            this.parent.model.get('secondary_condition').set("name", this.$el.find('secondary-condition-type option:selected').text());
        } else {
            this.parent.model.set(e.target.name, e.target.value);
        }
    },

    submit: function () {
        this.parent.render();
        this.remove();
    },

    close: function() {
        this.remove();
    }
});