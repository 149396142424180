MarketReportsApp.Views.MarketReportsView = Backbone.View.extend({

	className: 'app-container',

	events: {
		"change .report-select": "reportChange",
		"change .org-select": "orgChange"
	},

	initialize: function(){
		var self = this;

		$.ajax({
			url: '/assets/js/market-reports/history/templates/market-reports.html',
			success: function(extTemplate) {
				self.template = _.template(extTemplate, {});
				self.render();
			},
            cache: false
		});
	},

	render: function() {
		var selectModel = this.model.attributes.selectModels;
		this.$el.html(this.template(selectModel.toJSON()));

		$('.mr-history-app-container').append(this.$el);

		$('.org-select').select2({
			placeholder: "Select an Organization",
			ajax: {
				url: function(params) {
					return "/api/users/organization/autocomplete/" + params.term;
				},
				delay: 250
			},
			minimumInputLength: 2
		});

		var models = this.model.toJSON();
		this.reportSelectView = new MarketReportsApp.Views.ReportSelectView({model: models.selectModels});
		this.reportHistoryView = new MarketReportsApp.Views.ReportHistoryView();

        var baseUrl = document.location.href;
        if(baseUrl.indexOf('?') > -1) { // if the url contains a query param, fetch its model
            this.preselect(baseUrl);
        } else {
            $('.org-select').val(MarketReportsApp.Variables.CurrentOrganizationId); // prepopulate with current organization if there is no query param
            $('.org-select').trigger('change');
        }
	},

	reportChange: function() {
		MarketReportsApp.Variables.ReportID = $('.report-select').val();
		MarketReportsApp.Utils.toggleLoader();
		this.reportHistoryView.refresh();
	},

	orgChange: function() {
		MarketReportsApp.Variables.SelectOrganizationId = $('.org-select').val();
		this.reportSelectView.refresh();
		this.reportHistoryView.reset();
	},

    preselect: function(baseUrl) {
        var orgId = getParameterByName('org_id', baseUrl);

        $.ajax({
            type: 'GET',
            url: '/api/users/organization/id/' + orgId,
            success: function(response) {
                organization = response;
                var option = $('<option selected="selected"></option>').val(organization.id).text(organization.text);
                $('.org-select').append(option).trigger('change');
            }
        });
    }
});