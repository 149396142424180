MarketReportsApp.Views.AggregateBuilderView = Backbone.View.extend({

	events: {
		"change .primary-select": "checkDims",
		"change .secondary-select": "checkDims",
		"change .form-group": "updateName"
	},

	initialize: function() {
		var self = this;

		$.ajax({
			url: '/assets/js/market-reports/aggregate-builder/templates/aggregate-builder.html',
			success: function(extTemplate) {
				self.template = _.template(extTemplate);
			},
			async: false,
			cache: false
		});

		this.$el.html(this.template(this.model.toJSON()));
		$('.mr-aggregate-builder-app-container').append(this.$el);
	},

	checkDims: function() {
		if ($('.primary-select').val() != null && $('.secondary-select').val() != null){
			if ($('.primary-select').val() == $('.secondary-select').val()) {
				$('.primary-select').closest('.form-group').addClass('has-error');
				$('.secondary-select').closest('.form-group').addClass('has-error');

				$('.dim-value-match-message').removeClass('hidden');
			} else {
				$('.primary-select').closest('.form-group').removeClass('has-error');
				$('.secondary-select').closest('.form-group').removeClass('has-error');

				$('.dim-value-match-message').addClass('hidden');
			}
		}
	},

	updateName: function() {
		var properties = [
            $('[name="filter"] option:selected').text(),
            $('[name="primary"] option:selected').text(),
            $('[name="secondary"] option:selected').text(),
            $('[name="period"] option:selected').text(),
            $('[name="metric"] option:selected').text()
		];
		var name = properties.join(' - ').replace('None', '');
		$('[name="aggregateName"]').val(name);
	}
});