FeedsApp.Views.FeedHistory = Backbone.View.extend({
	events: {
		"click .view-stats": "viewHistory",
        "click .delete-button": "deleteFeed"
	},

	initialize: function(options) {
		var self = this;

		$.ajax({
			url: '/assets/js/feeds/history/templates/feed-history.html',
			success: function(extTemplate) {
				self.template = _.template(extTemplate);
				self.render();
			},
			dataType: 'html',
            cache:true
		});
	},

	render: function() {

		this.$el.html(this.template(this.collection));

		$('.feed-history-container').append(this.$el);
		this.renderRows();
	},

    renderRows: function() {

        var self = this;

        $.ajax({
            url: '/assets/js/feeds/history/templates/feed-history-row.html',
            success: function (extTemplate) {

                FeedsApp.Templates.FeedHistoryRow = _.template(extTemplate);
                self.collection.forEach(self.addOne, this);
                $('.feed-history').DataTable({
                    pageLength:10,
                    targets: 'noSort',
                    bSort: false,
                    order: []
                });
            },
            dataType: 'html',
            cache: false
        })
    },

    addOne: function(feedHistoryModel) {
        new FeedsApp.Views.FeedHistoryRow({ model: feedHistoryModel });
    },

	deleteFeed: function() {
        var feedId = FeedsApp.Variables.feedID;

        $.ajax({
			url: '/api/feeds/' + feedId,
			type: 'DELETE',
			success: function () {
                toastr["success"]("Feed has been deleted.");
                window.location = '/feeds';
			},
            error: function() {
                toastr["error"]("Feed failed to delete.");
            }
		});
	}

});