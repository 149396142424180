FeedsApp.Views.FeedsManage = Backbone.View.extend({

    events: {
        "change .org-select": "updateView",
        "change .user-only": "toggleFilterByUser"
    },

    userOnly: false,

    initialize: function(options) {
        var self = this;
        this.templates = options.templates;
        this.collection.orgId = options.orgId;
        this.collection.orgName = options.orgName;
        this.userId = options.userId;

        $.ajax({
            url: '/assets/js/feeds/management/templates/' + self.templates.table,
            success: function (extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
            },
            dataType: 'html',
            cache: false
        });
        $('.feeds-manage-app-container').append(this.$el);
    },

    render: function() {
        this.$el.html(this.template({
            orgId: this.collection.orgId,
            orgName: this.collection.orgName,
            userOnly: this.userOnly
        }));
        $('.org-select').select2({
            placeholder: this.orgName,
            ajax: {
                url: function(params) {
                    return "/api/users/organization/autocomplete/" + params.term;
                },
                delay: 250
            },
            minimumInputLength: 2
        });
        this.renderRows();
    },

    renderRows: function() {
        var self = this;

        $.ajax({
            url: '/assets/js/feeds/management/templates/' + self.templates.row,
            success: function (extTemplate) {
                FeedsApp.Templates.FeedRow = _.template(extTemplate);
                self.collection.forEach(self.addOne, this);
                $('#feedsTable').DataTable({
                    pageLength: 25,
                    columnDefs: [
                    ]
                });
                $('.feeds-manage-app-container').removeClass('sk-loading');
            },
            datatype: 'html',
            cache: false
        })
    },

    addOne: function(feed) {
        new FeedsApp.Views.FeedRow({ model: feed });
    },

    toggleFilterByUser: function(e) {
        var self = this;

        if (e.target.checked) {
            this.collection = new FeedsApp.Collections.FeedsManage(this.collection.where({user_id: this.userId}),
                {
                    orgId: this.collection.orgId,
                    orgName: this.collection.orgName
                }
            );
            this.userOnly = true;
            this.render();
        } else {
            this.userOnly = false;
            $('.feeds-manage-app-container').addClass('sk-loading');
            this.collection.fetch({
                reset: true,
                success: function() {
                    self.render();
                }
            });
        }
    },

    updateView: function(e) {
        var self = this;

        this.collection.orgId = e.target.value;
        this.collection.orgName = $(e.target).find('option:selected').text();

        FeedsApp.updateSelectedOrg(this.collection.orgId, this.collection.orgName);

        $('.feeds-manage-app-container').addClass('sk-loading');
        this.userOnly = false;
        this.collection.fetch({
            reset: true,
            success: function() {
                self.render();
            }
        });
    }
});