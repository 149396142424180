WhatsRunningApp.Models.Reports = Backbone.Model.extend({
    
    url: function() {
        return "/api/whats-running/reports"
    },
    
    defaults: {
        reports: ['initial state']
    },

    parse: function (data) {
        var model = {};
        model.reports = data;
        return model;
    }
});