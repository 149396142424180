WhatsRunningApp.Views.Feeds = Backbone.View.extend({
    className: "col-lg-12",

    initialize: function() {
        var self = this;

        this.timer = function () {
            self.model.set({feeds : ['initial state']})
            self.model.fetch()
        }

        $('.feeds').append(this.$el);

        $.ajax({
			url: '/assets/js/admin/whats-running/03_templates/feeds.html',
			success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.render();
                self.model.fetch();
			},
			cache: false
        });

        setInterval(this.timer.bind(this), 15000);
        this.model.on('change', this.render, this);
    },

    render: function() {
        var model = this.model.toJSON();
        this.$el.html(this.template(model));

        if (model.feeds.length != 1 || model.feeds[0] != 'initial state') {
            $('.feeds').toggleClass('sk-loading');
        }
    },

    close: function() {
        clearInterval(this.timer);
        this.remove();
        this.unbind();
    }
});