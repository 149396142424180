MarketReportsApp.Views.ReportBuilderPrimaryView = Backbone.View.extend({

	initialize: function() {
		var self = this;

		$.ajax({
			url: '/assets/js/market-reports/report-builder/templates/report-builder-primary.html',
			success: function(extTemplate) {
				self.template = _.template(extTemplate);
				$('.aggregate-value-selects').append(self.$el);
			},
            cache:true
		});
	},

	render: function() {
		
		var model = this.model.toJSON();

		this.$el.html(this.template(model));

		$('.aggregate-value-selects').removeClass('hidden');
		$('.loader').toggleClass('hidden');
		$('.name-and-submit').removeClass('hidden');

		return this.model.attributes.primary_dimension_type_id;
	},

	getDimensionTypeId: function() {
		return this.model.attributes;
	}

});