HomePageApp.Views.Chart = Backbone.View.extend({

    className: "col-lg-12",

    initialize: function() {
        var self = this;

        $('.chart-container').append(this.$el);

        $.ajax({
			url: '/assets/js/homepage/03_templates/chart.html',
			success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.model.getData();
                self.render();
			},
			cache: false
        });
        this.model.on('change', this.render, this);
    },

    render: function() {
        var model = this.model.toJSON();
        this.$el.html(this.template(model));

        if (model.dates != null && model.active != null && model.created != null && model.deleted != null) {
            this.updateChart();
        }
    },

    updateChart: function() {
        var model = this.model.toJSON();
        var ctx = $("#jobsChart");

        this.chart = new Chart(ctx, {
            responsive: true,
            type: 'bar',
            data: {
                datasets: [{
                    label: "Active Jobs",
                    yAxisID: 'Right',
                    data: model.active,
                    type: 'line',
                    backgroundColor: 'rgba(0,0,0,0)',
                    borderColor: 'rgba(0, 39, 76, 0.6)',
                }, {
                    label: "Created Jobs",
                    yAxisID: 'Left',
                    data: model.created,
                    backgroundColor: 'rgba(39, 180, 81, 0.6)',
                    borderColor: '#27b451'
                }, {
                    label: "Deleted Jobs",
                    yAxisID: 'Left',
                    data: model.deleted,
                    backgroundColor: 'rgba(212, 0, 21, 0.6)',
                    borderColor: '#d40015'
                }],

                labels: model.dates
            },

            options: {
                scales: {
                    yAxes: [{
                        id: 'Left',
                        position: 'left',
                        ticks: {
                            beginAtZero: false,
                            userCallback: function(value, index, values) {
                                value = value.toString();
                                value = value.split(/(?=(?:...)*$)/);
                                value = value.join(',');
                                return value;
                            }
                        }
                    }, {
                        id: 'Right',
                        position: 'right',
                        ticks: {
                            beginAtZero: false,
                            userCallback: function(value, index, values) {
                                value = value.toString();
                                value = value.split(/(?=(?:...)*$)/);
                                value = value.join(',');
                                return value;
                            }
                        }
                    }]
                }
            }
        });

        this.$('.ibox-content').toggleClass('sk-loading');
    }

});