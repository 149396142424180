DataPackagesApp.Views.Package = DynamicViewStandardModelEdition.extend({
    containerSelector: '.package-container',
    templateUrl: '/assets/js/data-packages/package-builder/templates/package.html',

    events: {
        "click .edit-button": "edit",
        "click .delete-confirmation": "openDeleteModal",
        "click .delete-button": "delete"
    },

    updateModel: function (e) {
        this.model.setModelAttribute(e.target.name, e.target.value);
    },

    edit: function() {
        console.log('edit');
        new DataPackagesApp.Views.EditPackage({
            model: this.model,
            collection: this.collection,
            containerSelector: this.$el.find('.edit-me')
        });
    },

    openDeleteModal: function() {
        this.$el.find('#confirm-delete').modal('show');
    },

    delete: function() {
        this.$el.find('#confirm-delete').modal('hide'); // Hiding the modal
        $('body').removeClass('modal-open'); // Closing the modal because being hidden is not enough
        $('.modal-backdrop').remove(); // Removing the 'fade' that prevents users from interacting with the page, because this doesn't happen when you hide and close the modal for really good reasons.
        this.model.destroy();
    }
});