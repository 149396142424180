MarketReportsApp.Views.ReportSelectView = Backbone.View.extend({

	tagName: "select",
	className: "report-select form-control",

	initialize: function() {
		var self = this;
		$.ajax({
			url: '/assets/js/market-reports/history/templates/report-select.html',
			success: function(extTemplate) {
				self.template = _.template(extTemplate);
				self.render();
			},
            cache: false
		});

		$('.report-select-container').append(this.$el);
	},

	render: function() {
		this.$el.html(this.template(this.model.toJSON()));
		var options = $('select.report-select option'); 
		var arr = options.map(function(_, o) { 
			return { 
				t: $(o).text(), 
				v: o.value 
			}; 
		}).get(); 
		arr.sort(function(o1, o2) { 
			var t1 = o1.t.toLowerCase(), t2 = o2.t.toLowerCase(); 
			return o1.t > o2.t ? 1 : o1.t < o2.t ? -1 : 0; 
		}); 
		options.each(function(i, o) { 
			o.value = arr[i].v;
			$(o).text(arr[i].t); 
		});
        // if the url contains a query param, fetch its model
        $('select.report-select').prepend('<option selected disabled hidden>Select a Report</option>');
        var baseUrl = document.location.href;
        if(baseUrl.indexOf('?') > -1) {
            this.preselect(baseUrl);
        }
	},

	refresh: function() {
		this.model.clear();
		var self = this;
		this.model.fetch({
			success: function() {
				self.render();
			}
		});
	},

    preselect: function(baseUrl) {
        // var reportId = baseUrl.substring(baseUrl.indexOf('?') + 1, baseUrl.length);
        // $('.report-select').val(reportId).change();
        var orgId = getParameterByName('org_id', baseUrl);
        var reportId = getParameterByName('report_id', baseUrl);
        if (orgId === MarketReportsApp.Variables.SelectOrganizationId) {
            $('.report-select').val(reportId).change();
        }
    }
});