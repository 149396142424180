MarketReportsApp.Views.ReportHistoryView = Backbone.View.extend({

    initialize: function() {

        var self = this;
        $.ajax({
            url: '/assets/js/market-reports/report-details/templates/report-history.html',
            success: function(extTemplate){
                self.template = _.template(extTemplate);
                self.render();
            },
            cache: false
        });
    },

    render: function() {
        var self = this;
        this.$el.html(this.template());

        $('.report-history-container').append(this.$el);

        this.renderReportHistoryRows();
        if (self.model.attributes.postProcessDefinition.length != 0) {
            $('.post-process-history').toggleClass('hidden');
            this.renderPostProcessHistoryRows();
        }
    },

    renderReportHistoryRows: function() {

        var self = this;

        var historyItems = self.model.attributes.reportHistory;
        /*
        mock data for for development since running a report is difficult to set up:

        var historyItemsLength = 10;
        var historyItems = new Array(historyItemsLength);
        for(var i = 0; i < historyItems.length; i++){
            historyItems[i] = new Array();
            historyItems[i]['date'] = "1536068555";
            historyItems[i]['historyId'] = "1fb43dc0-ac7e-11e8-848c-0242ac1f0b07";
            historyItems[i]['url'] = "https://dev.data.linkup.test/market-reports/details/1fb43dc0-ac7e-11e8-848c-0242ac1f0b07";
        }*/
        // end mock data
        $.ajax({
            url: '/assets/js/market-reports/report-details/templates/report-history-row.html',
            success: function(extTemplate){

                MarketReportsApp.Templates.ReportHistoryRow = _.template(extTemplate);

                historyItems.forEach(function(item){
                    new MarketReportsApp.Views.ReportHistoryRow({ model: item });
                });
                $('.report-history').DataTable({
                    pageLength:10,
                    targets: 'noSort',
                    bSort: false,
                    order: []
                });
            },
            dataType: 'html',
            cache: false
        })
    },

    renderPostProcessHistoryRows: function() {

        var self = this;

        var postProcessItems = self.model.attributes.postProcessReportHistory;
        /*mock data for for development since running a report is difficult to set up:

        var postProcessItemsLength = 3;
        var postProcessItems = new Array(postProcessItemsLength);
        for(var i = 0; i < postProcessItems.length; i++){
            postProcessItems[i] = new Array();
            postProcessItems[i]['date'] = "1536068555";
            postProcessItems[i]['historyId'] = "1fb43dc0-ac7e-11e8-848c-0242ac1f0b07";
            postProcessItems[i]['url'] = "https://dev.data.linkup.test/market-reports/details/1fb43dc0-ac7e-11e8-848c-0242ac1f0b07";
        }*/
        // end mock data
        $.ajax({
            url: '/assets/js/market-reports/report-details/templates/post-process-history-row.html',
            success: function(extTemplate){

                MarketReportsApp.Templates.PostProcessHistoryRow = _.template(extTemplate);

                postProcessItems.forEach(function(item){
                    new MarketReportsApp.Views.PostProcessHistoryRow({ model: item });
                });
                $('table.post-process-history').DataTable({
                    pageLength:10,
                    targets: 'noSort',
                    bSort: false,
                    order: []
                });
            },
            dataType: 'html',
            cache: false
        })
    },

});

