MarketReportsApp.Views.ReportBuilderStep3 = Backbone.View.extend({

	events: {
		"click .append-dimension": "appendDimension",
		"change .computation": "computationChange",
		"click .skip-post-processing": "skipPostProcessing"
	},

    initialize: function() {
		var self = this;

		this.model = new MarketReportsApp.Models.ReportBuilderComputations();
		$.ajax({
			url: '/assets/js/market-reports/report-builder/templates/report-builder-step-3.html',
			success: function(extTemplate) {
				self.template = _.template(extTemplate);
				$('#step3').append(self.$el);
			},
            cache:true
		});

		$.ajax({
			url: '/assets/js/market-reports/report-builder/templates/report-builder-computations-config.html',
			success: function(extTemplate) {
				self.configTemplate = _.template(extTemplate);
			},
            cache:true
		});

		$.ajax({
			url: '/assets/js/market-reports/report-builder/templates/report-builder-appended-data.html',
			success: function(extTemplate) {
				MarketReportsApp.Templates.AppendedData = _.template(extTemplate);
			},
            cache:true
		});

		// These arrays are used to track the repeatable views, in the event that they all need to be removed at the same time (when a different computation is selected)
		MarketReportsApp.Variables.AppendedDimensionViews = [];
    },
    
    render: function() {
		var self = this;
		this.model.set("reportFormat", MarketReportsApp.Variables.reportBuilderModel.toJSON().format);

		this.$el.html(this.template(this.model.toJSON()));

        if (MarketReportsApp.Variables.EditReport) {
            if (MarketReportsApp.Variables.EditReport.postProcess === null) {
                $('.skip-post-processing').click();
            } else {
                self.prepopulate();
            }
        } else {
            $('.skip-post-processing').click();
        }
	},

	appendDimension: function() {
		var appendedDataView = new MarketReportsApp.Views.AppendedDataView({model: this.model});
		MarketReportsApp.Variables.AppendedDimensionViews.push(appendedDataView);
	},

	computationChange: function() {
		var self = this;
		MarketReportsApp.Variables.ComputationID = $('.computation').val();
		this.model.fetch({
			reset: true,
			success: function() {
				self.computationConfig();
				if (MarketReportsApp.Variables.EditReport && MarketReportsApp.Variables.EditReport.postProcess) {
                    self.prepopulate2();
				}
			}
		});

		$('.computation-config').empty();

		$.each(MarketReportsApp.Variables.AppendedDimensionViews, function() {
			this.preRemove();
		});
	},

	computationConfig: function() {
		var model = this.model.toJSON();
		if (model.configurable == true) {
			$('.computation-config').html(this.configTemplate(model));
		}

		//this is maybe not the best way to do this
		//if we have options we want to make sure to show the button to configure them
		//if we don't have options we want to make sure we hide the button.
        if(model.appendedFieldOptions.length > 0) {
            $('.add-appended-dims').removeClass('hidden');
        } else {
            $('.add-appended-dims').removeClass('hidden');
            $('.add-appended-dims').addClass('hidden');
		}
	},

	skipPostProcessing: function() {
		$('.post-process-form').toggleClass('hidden');
	},

    prepopulate: function() {
        if (MarketReportsApp.Variables.EditReport.postProcess.reports_post_process_files.include_primary_dimension_in_rows == true) {
            $('#include-primary-dimension-in-rows').prop( "checked", true );
        }

		if (MarketReportsApp.Variables.EditReport.postProcess.reports_post_process_files.with_headers === true) {
			$('.with-headers').prop( "checked", true );
		}

        if (MarketReportsApp.Variables.EditReport.postProcess.computation.reports_computations.id === null) {
            $('.computation').val("None").change();
        } else {
			$('.computation').val(MarketReportsApp.Variables.EditReport.postProcess.computation.reports_computations.id).change();
        }
    },

    prepopulate2: function() {
		var self = this;

        MarketReportsApp.Variables.existingAppendedDims = [];
        _.each(MarketReportsApp.Variables.EditReport.postProcess.appended_dims, function(dimension) {
            self.appendDimension();
            MarketReportsApp.Variables.existingAppendedDims.push(dimension.reports_appended_dimensions.id);
        });

        _.each(MarketReportsApp.Variables.AppendedDimensionViews, function(view, index) {
            view.$('.appended-field').val(MarketReportsApp.Variables.existingAppendedDims[index]);
        });
    }
});