CompanyListApp.Views.ParentTable = Backbone.View.extend({

    tagName: "table",
    className: "table table-striped table-hover jde-data-table-active",

    events: {
        'click button.edit-details': 'editDetails',
        'click button.delete-company': 'deleteCompany'
    },

    initialize: function() {
        var self = this;
        $.ajax({
            url: '/assets/js/company-list/templates/parent-company-table.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.listenTo(self.model, 'change', self.render);
                $('div.parent-company').append(self.$el);
                self.render();
            },
            dataType: 'html',
            cache: false
        });
    },

    render: function() {
        this.$el.html(this.template(this.model.attributes));
        return this;
    },

    editDetails: function() {
        new CompanyListApp.Views.AddCompany({model: this.model});
    },

    deleteCompany: function() {
        this.model.destroy();
    }
});