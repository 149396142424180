// Whatever instantiates this should decide whether the model needs to be fetched prior to view creation and pass it in without waiting for the model to sync.
var DynamicViewStandardModelEdition = Backbone.View.extend({
    containerSelector: null,
    templateUrl: null,

    initialize: function(options) {
        if (options.containerSelector) {
            this.containerSelector = options.containerSelector;
        }
        if (options.templateUrl) {
            this.templateUrl = options.templateUrl;
        }

        $(_.result(this, 'containerSelector')).append(this.$el);
        this.fetchTemplate(_.result(this, 'templateUrl'));
    },

    fetchTemplate: function(url) {
        var self = this;
        $.ajax({
            url: url,
            success: function(data) {
                self.template = _.template(data);
                self.render();
                self.model.on({
                    'change': self.render,
                    'destroy remove': self.remove,
                    'error': function(model, response, options) {
                        toastr['error'](response.responseText);
                    }
                }, self);
            }
        });
    },

    render: function() {
        this.$el.html(this.template(this.model.toJSON()));
        return this;
    }
});

// Pass in the empty collection, this view will sync
var DynamicViewSpecialCollectionEdition = Backbone.View.extend({
    containerSelector: null,
    templateUrl: null,

    // this must be defined as a function(model, collection, options) { return new DynamicViewStandardModelEdition({model: model}); };
    createNewModelView: false,

    initialize: function(options) {
        if (options.containerSelector) {
            this.containerSelector = options.containerSelector;
        }
        if (options.templateUrl) {
            this.templateUrl = options.templateUrl;
        }

        $(_.result(this, 'containerSelector')).append(this.$el);
        this.fetchTemplate(_.result(this, 'templateUrl'));
    },

    fetchTemplate: function(url) {
        var self = this;
        $.ajax({
            url: url,
            success: function(data) {
                self.template = _.template(data);
                self.render();
                self.collection.fetch();
                // on fetch, new models trigger add events, removed models trigger remove events, and changed models trigger change events
                // destroy, remove, and change events are handled by model view
                self.collection.on('add', self.createNewModelView, self);
            }
        });
    },

    render: function() {
        this.$el.html(this.template());
        return this;
    }
});
